import axios from "axios";
import baseAxios from "../core/api/axios/baseAxios";
import investmentBaseAxios from "../core/api/axios/investmentBaseAxios"
import newbaseAxios from "../core/api/axios/newBaseAxios"

export default class OrganisationService {
  /****************************
   *
   * get organizations profile
   *
   ****************************/

  static async getOrganisationProfile() {
    const res = await baseAxios.get(`/organisation/profile`);
    return res?.data || res;
  }

  /****************************
   *
   * get organizations
   *
   ****************************/

  static async GetOrganisations(page, currentMonth) {
    const res = await baseAxios.get(
      // `/v2/organisations?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}`
      `/v2/admin/organisations?status=active&approvalStatus=approved`
    );
    return res?.data || res;
  }
  static async GetAwaitingOrganisations(page, currentMonth) {
    const res = await baseAxios.get(
      // `/v2/organisations?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}`
      `/v2/admin/organisations?status=disabled&approvalStatus=pending`
    );
    return res?.data || res;
  }
  static async GetDisabledOrganisations(page, currentMonth) {
    const res = await baseAxios.get(
      // `/v2/organisations?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}`
      `/v2/admin/organisations?status=disabled`
    );
    return res?.data || res;
  }
  static async GetRejectedOrganisations(page, currentMonth) {
    const res = await baseAxios.get(
      // `/v2/organisations?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}`
      `/v2/admin/organisations?status=disabled&approvalStatus=rejected`
    );
    return res?.data || res;
  }

  static async getAllOrganisations({ allowPickers = true }) {
    const res = await baseAxios.get(
      `/v2/organisations?allowPickers=${allowPickers}`
    );
    return res?.data || res;
  }

  /****************************
   *
   * create organizations
   *
   ****************************/

  static async createOrganisation(data) {
    // const res = await baseAxios.post("/v2/organisation/create", data);
    console.log(data)
    const res = await newbaseAxios.post("v2/admin/organisation/onboarding", data);
    console.log(res)
    return res?.data || res;
  }
  
  static async UploadOrganisationImage(data) {
    console.log(data)
    
    const res = await axios.post("https://beta.pakam.ng/collector/api/v2/admin/organisation/uploadimage", data);
    console.log(res)
    return res?.data || res;
  }

  /****************************
   *
   * update organization
   *
   ****************************/

  static async UpdateOrganisation(id, data) {
    const res = await baseAxios.put(`/v2/sadmin/organization/update/${id}`, data);
    return res?.data || res;
  }
  static async UpdateOrganisationStatus(id, data) {
    const res = await baseAxios.put(`/v2/admin/organisation/status/${id}`, data);
    return res?.data || res;
  }

  static async updateOrganisationCompany(data) {
    const res = await baseAxios.put(`/v2/company/update`, data);
    return res?.data || res;
  }
  /****************************
   *
   * find organization
   *
   ****************************/

  static async findOrganisation(id) {
    const res = await baseAxios.get(`/v2/organisation/profile/${id}`);
    return res?.data || res;
  }

  /****************************
   *
   * search organizations
   *
   ****************************/

  static async searchOrganisations(page, key) {
    const res = await baseAxios.get(
      `/v2/organisations?key=${key}&page=${page}`
    );
    return res?.data || res;
  }

  /****************************
   *
   * delete organization
   *
   ****************************/

  static async deleteOrganisation(id) {
    const res = await baseAxios.delete(`/v2/organisation/remove/${id}`);
    return res?.data || res;
  }

  static async disableOrganisation(id) {
    const res = await baseAxios.put(`/v2/organisation/disable/${id}`);
    return res?.data || res;
  }

  static async enableOrganisation(id) {
    const res = await baseAxios.put(`/v2/organisation/enable/${id}`);
    return res?.data || res;
  }

  /****************************
   *
   * see all aggregators
   *
   ****************************/

  static async getOrganisationAggregators(page, id, currentMonth) {
    const res = await baseAxios.get(
      `/v2/organisation/aggregators/${id}?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}`
    );
    return res?.data || res;
  }

  /****************************
   *
   * search all aggregators
   *
   ****************************/

  static async searchOrganisationAggregators(page, id, key) {
    const res = await baseAxios.get(
      `/v2/organisation/aggregators/${id}?page=${page}&key=${key}`
    );
    return res?.data || res;
  }

  static async getOutstandingInvoice(id, currentMonth, page) {
    const res = await baseAxios.get(
      `invoice/${id}/history?start=${currentMonth.start}&end=${currentMonth.end}&paid=false&page=${page}`
    );
    return res?.data || res;
  }

  static async getCompletedInvoice(id, currentMonth, page) {
    const res = await baseAxios.get(
      `invoice/${id}/history?start=${currentMonth.start}&end=${currentMonth.end}&paid=true&page=${page}`
    );
    return res?.data || res;
  }

  static async getGeneratedInvoice(data) {
    const {start, end, companyId} = data
    const id = companyId.split('-')[1]
    const payload = {
      start : start,
      end:end,
      companyId:id
    }
    console.log(payload)
    const res = await baseAxios.post("/v2/invoice/generate", payload);
    return res?.data || res;
  }

  static async searchOutstandingInvoice(id, key, page) {
    const res = await baseAxios.get(
      `invoice/${id}/history?paid=false&key=${key}&page=${page}`
    );
    return res?.data || res;
  }

  // static async getCompletedSchedules(currentMonth) {
  //   const res = await baseAxios.get(
  //     `/v2/schedules/625ec5872995f00023516971?start=${currentMonth.start}&end=${currentMonth.end}`
  //   );
  //   return res?.data || res;
  // }

  static async getCompletedSchedules(id, currentMonth) {
    const res = await baseAxios.get(
      `/v2/schedules/${id}?start=${currentMonth.start}&end=${currentMonth.end}&type=dropoff`
    );
    return res?.data || res;
  }

  static async searchCompletedDropOffSchedules(id, key, page) {
    const res = await baseAxios.get(
      `/v2/schedules/${id}?key=${key}&page=${page}&type=dropoff`
    );
    return res?.data || res;
  }

  static async filterCompletedDropOffSchedules(id, currentMonth, page) {
    const res = await baseAxios.get(
      `/v2/schedules/${id}?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}&type=dropoff`
    );
    return res?.data || res;
  }

  static async getCompletedPickupSchedules(id, currentMonth, page) {
    const res = await baseAxios.get(
      `/v2/schedules/${id}?start=${currentMonth.start}&end=${currentMonth.end}&type=pickup&page=${page}`
    );
    return res?.data || res;
  }

  static async searchCompletedPickupSchedules(id, key, page) {
    const res = await baseAxios.get(
      `/v2/schedules/${id}?key=${key}&page=${page}&type=pickup`
    );
    return res?.data || res;
  }

  // static async filterCompletedPickupSchedules(id, page, currentMonth) {
  //   const res = await baseAxios.get(
  //     `/v2/schedules/${id}?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}&type=pickup`
  //   );
  //   return res?.data || res;
  // }

  static async filterCompletedPickupSchedules(id, currentMonth, page) {
    const res = await baseAxios.get(
      `/v2/schedules/${id}?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}&type=pickup`
    );
    return res?.data || res;
  }

  // static async searchCompletedPickupSchedules(key, page) {
  //   const res = await baseAxios.get(
  //     `/v2/schedules/625ec5872995f00023516971?key=${key}&page=${page}&type=pickup`
  //   );
  //   return res?.data || res;
  // }

  // /v2/schedules/625ec5872995f00023516971?key=Daniel

  static async searchCompletedInvoice(id, key, page) {
    const res = await baseAxios.get(
      `invoice/${id}/history?paid=true&key=${key}&page=${page}`
    );
    return res?.data || res;
  }

  static async filterOutstandingInvoice(currentMonth, page, id) {
    const res = await baseAxios.get(
      `invoice/${id}/history?start=${currentMonth.start}&end=${currentMonth.end}&paid=false&page=${page}`
    );
    return res?.data || res;
  }

  static async filterCompletedInvoice(currentMonth, page, id) {
    const res = await baseAxios.get(
      `invoice/${id}/history?start=${currentMonth.start}&end=${currentMonth.end}&paid=true&page=${page}`
    );
    return res?.data || res;
  }

  static async downloadInvoice(data) {
    const res = await baseAxios.get(`invoice/send/${data}`);
    return res?.data || res;
  }
}

// send invoice - {{localURL}}invoice/send/530208216
