import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import CategorySelect from "../../components/UI/CategorySelect";
import Button from "../../components/UI/button";
import MultiSelect from "../../components/UI/multiSelect";
import Select from "../../components/UI/select";
import FormInput from "../../components/auth/FormInput";
import { FlexContainer, FormContainer } from "../../components/styledElements";
import useForm from "../../hooks/useForm";
import {
  findOrganisation,
  getAllAreas,
  getAllLocations,
  getCategory,
  getOrganisations,
  getStateAreas,
  getSubAreas,
  updateOrganisation,
  uploadOrganisationImage,
} from "../../store/actions";
import {
  formatOrgDetails,
  formatSelectOptions,
  valAggregator,
} from "../../utils";
import Modal from "../../components/UI/modal";
import ImageUpload from "../../components/UI/ImageUpload";
import { TabContainer } from "../../components/UI/TabContent";
import { Tabs } from "antd";

const { TabPane } = Tabs;



const SetupOrganizationContainer = styled.div`
  ${tw`grid items-center gap-4 w-full`}
`;

const InfoContainer = styled.div`
  ${tw`bg-white pt-4 pb-1 px-10`}
`;

const FormTitle = styled.h1`
  ${tw`text-2xl font-extrabold tracking-wide `}
`;
// const DocumentContainer = styled.div`
//   ${tw`bg-white pt-8 pb-4 px-10`}
// `;
const WastePricingContainer = styled.div`
  ${tw`bg-white pt-8 pb-2 px-10 min-h-[30vh]`}
`;

const Br = styled.div`
  margin-bottom: 3rem;
`;

let bioFormEntries = {
  name: {
    label: "Name",
    value: "",
    placeholder: "Organisation Name",
    rules: [(v) => !!v || "Company Name is required"],
  },
  phone: {
    label: "Contact Line",
    value: "",
    placeholder: "Organisation Contact",
    rules: [
      (v) => !!v || "Contact Line is required",
      (v) =>
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(v) ||
        "contact line is invalid",
    ],
  },
  email: {
    label: "Email Address",
    value: "",
    placeholder: "Organisation Email",
    rules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "▲ E-mail must be valid",
    ],
  },
  address: {
    label: "Address",
    value: "",
    placeholder: "Organization Location",
    rules: [(v) => !!v || "Company Address is required"],
  },
};

let documentsFormEntries = {
  RCNumber: {
    label: "RC Number",
    value: "",
    placeholder: "RC Number",
    rules: [(v) => !!v || "RCNumber is required"],
  },
  tag: {
    label: "Assign Tag",
    value: "",
    placeholder: "Organisation Tag",
    rules: [(v) => !!v || "Organisation Tag is required"],
  },
  country: {
    label: "Country",
    optionIdx: null,
    type: "select",
    options: [],
    rules: [(v) => !!v || "Country is required"],
  },
  state: {
    label: "State",
    optionIdxs: [],
    type: "select",
    options: [],
    rules: [(v) => !!v || "State is required"],
  },
  areaAccess: {
    label: "Area of Access",
    optionIdxs: [],
    type: "multiselect",
    options: [],
    rules: [(v) => !!v || "Areas of Access is required"],
  },
  lcda: {
    label: "Area Under Selected LCDA",
    optionIdxs: [],
    options: [],
    type: "multiselect",
    rules: [(v) => !!v || "choose some option(s)"],
  },
};

const ModifyOrganization = ({ match }) => {
  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)
  // categories
  const [bodyCategories, setBodyCategories] = useState([])
  const [aggCategories, setAggCategories] = useState([]);
  const [indCategories, setIndCategories] = useState([]);
  // activate dropoffs
  const [activated, setActivate] = useState(true)
  const [aggActivated, setAggActivate] = useState(false)
  const [indActivated, setIndActivate] = useState(false)
  
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [fetchedAggCategories, setFetchedAggCategories] = useState([]);
  // category values
  const [catValues, setCatValues] = useState({});
  const [aggCatValues, setAggCatValues] = useState({});
  const [indCatValues, setIndCatValues] = useState({});
  // dropoff values
  const [dropValues, setDropValues] = useState({dropOff:''});
  const [aggDropValues, setAggDropValues] = useState({dropOff:''});
  const [indDropValues, setIndDropValues] = useState({dropOff:''});

  const [fetchedData, setFetchedData] = useState({});
  const [lgaIndex, setLgaIndex] = useState([]);
  const [fetchedCovArea, setFetchedCovArea] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [profileBanner, setProfileBanner] = useState(null);
  const [fetchedArea, setFetchedArea] = useState([]);
  const [lcds, setLcds] = useState([]);
  const [subAreaOptions, setSubAreaOptions] = useState([]);

  const {
    organisation: { selectedOrganisation },
    wasteCategory: { category },
    location: { locations },
    area: { allAreas },
  } = useSelector((state) => state);

  const categoryIdxs = useRef();
  const date = new Date();
  const currentMonth = {
    start: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "YYYY-MM-DD"
    ),
    end: moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format(
      "YYYY-MM-DD"
    ),
  };

  const dispatch = useDispatch();
  let history = useHistory();

  const { setValue, errorMsgs, formValues } = useForm(bioFormEntries);

  const {
    setValue: setDocVal,
    errorMsgs: docErrMsg,
    formValues: docFormValues,
  } = useForm(documentsFormEntries);

  const {
    setValue: setCatVal,
    errorMsgs: catErrMsg,
    formValues: catFormValues,
  } = useForm(catValues);

  const {
    setValue: setAggCatVal,
    errorMsgs: aggCatErrMsg,
    formValues: aggCatFormValues,
    isValid: isValidAggCat,
  } = useForm(aggCatValues);
  const {
    setValue: setIndCatVal,
    errorMsgs: indCatErrMsg,
    formValues: indCatFormValues,
    isValid: isValidIndCat,
  } = useForm(indCatValues);

  const {
    setValue: setDropVal,
    errorMsgs: dropErrMsg,
    formValues: dropFormValues,
    isValid: isValidDrop,
  } = useForm(dropValues);

  const {
    setValue: setAggDropVal,
    errorMsgs: aggDropErrMsg,
    formValues: aggDropFormValues,
    isValid: isValidAggDrop,
  } = useForm(aggDropValues);

  const {
    setValue: setIndDropVal,
    errorMsgs: indDropErrMsg,
    formValues: indDropFormValues,
    isValid: isValidIndDrop,
  } = useForm(aggDropValues);

  useEffect(() => {
    dispatch(findOrganisation(match?.params?.id));
    // fetch all available categories from server
    if (!category) dispatch(getCategory());
  }, []);

  useEffect(() => {
    // format the server-sent categories to as required
    // in category select options
    if (category)
      setCategories(
        category.map((cat) => ({
          ...cat,
          text: cat.name,
        }))
      );
  }, [category]);

  useEffect(() => {
    const categoriesEntries = {};
    const categoriesExistingEntries = {};

    const categoryFullObj = (category) => {

      return fetchedCategories?.find(
        (cat) => cat.name.toLowerCase() === category.toLowerCase()
      );
    };

    if (bodyCategories) {
      /**************************************************************************************
       * since bodyCategories is updated inside categorySelect when new category is selected
       * reconstruct the category form entries in a way that caters for the exisiting price
       * saved in the form entries and also include the new selected category which is
       * yet to get a price.
       **************************************************************************************/
      let obj;
      bodyCategories &&
        bodyCategories.forEach((entry) => {
          obj = categoryFullObj(entry.name || entry.text);
 
          categoriesExistingEntries[entry.name || entry.text] = {
            label: obj?.name || entry.text || entry.name,
            value:  entry.price || obj?.price,
            placeholder: "Price rate for 1kg",
            type: "number",
            rules: [
              (v) => !!v || "choose some option(s)",
              (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",
            ],
          };
        });
    }

    const combinedEntries = {
      ...categoriesExistingEntries,
      ...categoriesEntries,
    };
    setCatValues(combinedEntries);
  }, [bodyCategories, fetchedCategories]);

  useEffect(() => {
    const aggCategoriesEntries = {};
    const aggCategoriesExistingEntries = {};

    const categoryFullObj = (category) => {

      return fetchedAggCategories?.find(
        (cat) => cat.name.toLowerCase() === category.toLowerCase()
      );
    };

    if (aggCategories) {
      /**************************************************************************************
       * since bodyCategories is updated inside categorySelect when new category is selected
       * reconstruct the category form entries in a way that caters for the exisiting price
       * saved in the form entries and also include the new selected category which is
       * yet to get a price.
       **************************************************************************************/

      let obj;
      aggCategories &&
        aggCategories.forEach((entry) => {
          obj = categoryFullObj(entry.name || entry.text);
 
          aggCategoriesExistingEntries[entry.name || entry.text] = {
            label: obj?.name || entry.text || entry.name,
            value:  entry.price || obj?.price,
            placeholder: "Price rate for 1kg",
            type: "number",
            rules: [
              (v) => !!v || "choose some option(s)",
              (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",
            ],
          };
        });
    }

    const combinedEntries = {
      ...aggCategoriesExistingEntries,
      ...aggCategoriesEntries,
    };
    setAggCatValues(combinedEntries);
  }, [aggCategories, fetchedCategories]);

  useEffect(() => {
    const indCategoriesEntries = {};
    const indCategoriesExistingEntries = {};

    const categoryFullObj = (category) => {

      return fetchedCategories?.find(
        (cat) => cat.name.toLowerCase() === category.toLowerCase()
      );
    };

    if (indCategories) {
      /**************************************************************************************
       * since bodyCategories is updated inside categorySelect when new category is selected
       * reconstruct the category form entries in a way that caters for the exisiting price
       * saved in the form entries and also include the new selected category which is
       * yet to get a price.
       **************************************************************************************/
      console.log(indCategories)
      let obj;
      indCategories &&
        indCategories.forEach((entry) => {
          obj = categoryFullObj(entry.name || entry.text);
 
          indCategoriesExistingEntries[entry.name || entry.text] = {
            label: obj?.name || entry.text || entry.name,
            value:  entry.price || obj?.price,
            placeholder: "Price rate for 1kg",
            type: "number",
            rules: [
              (v) => !!v || "choose some option(s)",
              (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",
            ],
          };
        });
    }

    const combinedEntries = {
      ...indCategoriesExistingEntries,
      ...indCategoriesEntries,
    };

    console.log(combinedEntries)
    setIndCatValues(combinedEntries);
  }, [indCategories, fetchedCategories]);
  
  const handleImageUpload = async( imageData, fieldName, fileExtension) => {
    try {
      const res = await dispatch(uploadOrganisationImage({
        "image":imageData,
        "imageType":fileExtension
    }))
      console.log(res)

      if (fieldName === 'profileImage') {
        setProfileImage(res.payload.data);
      } else if (fieldName === 'profileBanner') {
        setProfileBanner(res.payload.data);
      }
    } catch (error) {
      
    }


  };

  useEffect(() => {
    if (selectedOrganisation) {
      
      [bioFormEntries, documentsFormEntries] = formatOrgDetails(
        selectedOrganisation,
        lgaIndex,
        fetchedArea,
        lcds,
        locations,
        docFormValues
      );

      // // Update formValues using setValue method from useForm hook
      // Object.entries(bioFormEntries).forEach(([key, value]) => {
      //   setValue(key, value.value);
      // });

      // // Update docFormValues using setDocVal method from useForm hook
      // Object.entries(documentsFormEntries).forEach(([key, value]) => {
      //   setDocVal(key, value.value);
      // });

      // console.log(bioFormEntries)
      setFetchedData(selectedOrganisation?.organisationprofile);
      // docFormValues.country=selectedOrganisation.country
      // save the organisation categories inside bodyCategories
      // setDropValues({dropOff:selectedOrganisation?.percentage});
      // setAggDropValues({dropOff:selectedOrganisation?.percentage});
      setBodyCategories(selectedOrganisation?.organisationPricing[0]?.categories);
      setAggCategories(selectedOrganisation?.organisationPricing[1]?.categories);
      setIndCategories(selectedOrganisation?.organisationPricing[2]?.categories);

    }
  }, [
    selectedOrganisation,
    lcds,
    subAreaOptions,
    lgaIndex,
    subAreaOptions,
    locations,
  ]);

  // useEffect(()=>{
  //   if(selectedOrganisation)docFormValues.state = (selectedOrganisation?.state).toLowerCase()
    
  // },[selectedOrganisation])

  // updates the lcda options when changes occur in the LGA multiselect component
  useEffect(() => {
    if (!docFormValues.areaAccess) return;

    // gather the street options from available street options
    const streets = subAreaOptions.filter((area) =>
      docFormValues.areaAccess.find((str) => str === area.lga)
    );
    const streetOptions = formatSelectOptions(streets, "lcd", "slug");
    documentsFormEntries.lcda.options = streetOptions;

    // this is to trigger re-render
    setFetchedCovArea(streets);
  }, [docFormValues.areaAccess]);

  // Categories fillers
  useEffect(() => {
    if (fetchedData) {
      setFetchedCategories(fetchedData?.householdCategoriesPricing);
      setFetchedAggCategories(fetchedData?.aggregateCategoriesPricing);
      // setFetchedCategories(fetchedData?.categories);
    }
    
    // update the available categories state options when changes occur in the
    // fetched organisation data
    if (categories && fetchedData?.categories) {
      const catList = categories.map((i)=>i.items)
      const subcatList = catList.flat()      
      categoryIdxs.current = fetchedData.categories.map((cat) =>
        subcatList.findIndex(
          (c) => c._id === cat?.catId?._id
        )
      );

      // categoryIdxs.current = fetchedData.categories.map((cat) =>
      //   categories.findIndex(
      //     (c) => c.name.toLowerCase() === cat.name.toLowerCase()
      //   )
      // );
    }
  }, [fetchedData, categories]);

  useEffect(() => {
    if (!allAreas) dispatch(getAllAreas());
    if (allAreas) {
      const uniqueAreas = allAreas

        ?.map((area) => area.lga)
        .filter((area, idx, lg) => lg.indexOf(area) === idx)
        .map((area) => ({
          text: area,
          value: area,
        }));

      setFetchedArea(uniqueAreas);
    }
  }, [allAreas, dispatch]);

  // Populate the lgas
    useEffect(() => {
      let lgaIdx = [];
      console.log(fetchedArea)
      if (fetchedArea && fetchedData) {
        lgaIdx = fetchedData?.areaOfAccess
          ? fetchedData?.areaOfAccess?.map((area) =>
              fetchedArea?.findIndex(
                (fd) => fd.text?.toLowerCase() === area?.toLowerCase()
              )
            )
          : null;
      }
      setLgaIndex(lgaIdx);
    }, [fetchedArea, fetchedData]);

  // populate lcda
  useEffect(() => {
    if (subAreaOptions) {
      const cArea = subAreaOptions.map((ca) => ({
        text: ca?.lcd,
        value: ca?.slug,
      }));
      setLcds(cArea);
    }
  }, [subAreaOptions]);

  useEffect(() => {
    if (!locations) dispatch(getAllLocations());
    // return if there's no selected country or locations available
    if (!docFormValues.country) return;
    console.log(docFormValues.country)
    //get the array of states for the selected country
    const availableStates =
      locations.find((location) => location.country === docFormValues.country)
        .states || [];

    // format the states into the select component options format
    const stateOptions = formatSelectOptions(availableStates);

    // set the state options into the state select component
    documentsFormEntries.state.options = stateOptions;
  }, [docFormValues.country, locations]);

  
  useEffect(() => {
    console.log(docFormValues.state)
    // return if state isn't selected
    if (!docFormValues.state) return;

    const handleAreaResponse = async (res) => {
      // format the area response into select options format
      const areaOptions = formatSelectOptions(res.data, "lga");

      // set the available lga options to areaOptions
      documentsFormEntries.areaAccess.options = areaOptions;
      setFetchedArea(areaOptions);

      // fetch the subareas available for the state
      const subAreasResult = await dispatch(getSubAreas(docFormValues.state));
      if (!subAreasResult.error) setSubAreaOptions(subAreasResult.payload.data);
    };



    // request for areas(lgas) of selected state and handle response
    dispatch(getStateAreas(docFormValues.state))
      .unwrap()
      .then(handleAreaResponse)
      .catch((err) => console.log(err));
  }, [docFormValues.state]);


  const handleSubmit = () => {

    const household_cat =  Object.entries(catFormValues).map(([key, input]) => ({
      name: key,
      price: +input,
    }))
    const aggregator_cat =  Object.entries(aggCatFormValues).map(([key, input]) => ({
      name: key,
      price: +input,
    }))
    const industrial_cat =  Object.entries(indCatFormValues).map(([key, input]) => ({
      name: key,
      price: +input,
    }))

    const orgFormData = {
      "allowWastePicker":false,
      "companyName":formValues.name,
      "email":formValues.email,
      "rcNo":docFormValues.RCNumber,
      "companyTag": docFormValues.tag,
      "phone":formValues.phone,
      "areaOfAccess":docFormValues.areaAccess,
      "streetOfAccess":docFormValues.lcda,
      "householdCatPricing":household_cat.filter(obj => obj.name !== 'subcategories') || [],
      "aggregatorCatPricing":aggregator_cat.filter(obj => obj.name !== 'subcategories') || [],
      "industrialCatPricing":aggregator_cat.filter(obj => obj.name !== 'subcategories') || [],
      "location":formValues.address,
      "country":docFormValues.country,
      "state":docFormValues.state,
      "householdDropoffPercentage":Number(dropFormValues.dropOff),
      "aggregatorDropoffPercentage":Number(aggDropFormValues.dropOff),
      "industrialDropoffPercentage":Number(aggDropFormValues.dropOff),
      "bannerUrl":profileBanner,
      "logo":profileImage,
};

    const payload = {
      id: match?.params?.id,
      orgFormData,
    };

    console.log(payload)

    dispatch(updateOrganisation(payload))
      .unwrap()
      .then(() => {
        dispatch(getOrganisations(currentMonth));
        history.push("/admin/total_organizations");
      });
  };


useEffect(()=>{
  if(dropValues.dropOff) setDropVal('dropOff', dropValues.dropOff)
}, [dropValues])

const callback = (key) => {
  // onSwitch(key);
};



  return (
    <SetupOrganizationContainer>
      <InfoContainer>
        <FormTitle>Bio</FormTitle>
        <FormContainer>
          {Object.entries(bioFormEntries).map(([key, input]) => (
            <div className="w-full md:w-[45%] md:gap-x-10">
              <FormInput
                placeholder={input.placeholder}
                type={input.type}
                label={input.label || input.name}
                key={input.label || input.name}
                changeHandler={(e) => setValue(key, e.target.value)}
                errorMsg={errorMsgs[key]}
                value={formValues[key]}
                extraLink={input.extraLink}
                disabled={input.disabled}
              />
            </div>
          ))}

          <div>
                <ImageUpload
                  title="Organization Logo" 
                  fieldName="profileImage" 
                  url={selectedOrganisation?.logo}
                  onImageUpload={handleImageUpload}
                />

          </div>

          <div>
          <ImageUpload 
            title="Organization Banner" 
            fieldName="profileBanner" 
            url={selectedOrganisation?.imageUrl}
            onImageUpload={handleImageUpload}
          />

        </div>
        </FormContainer>
      </InfoContainer>
      <InfoContainer>
        <FormTitle>Documents</FormTitle>
        <FormContainer>
          <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
            {Object.entries(documentsFormEntries).map(([key, input]) => {
              switch (input.type) {
                case "select":
                  return (
                    <Select
                      key={key}
                      width="100%"
                      height="3.5rem"
                      plain={true}
                      options={input.options}
                      label={input.label}
                      title={input.label}
                      changeHandler={(v) => setDocVal(key, v)}
                      optionIdx={input.optionIdx !== null && input.optionIdx}
                      disabled={input.disabled}
                      checkbox={input.checkbox}
                    />
                  );
                case "multiselect":
                  return (
                    <MultiSelect
                      key={key}
                      width="100%"
                      height="3.5rem"
                      plain={true}
                      options={input.options}
                      label={input.label}
                      title={input.title || input.label}
                      changeHandler={(v) => setDocVal(key, v)}
                      optionIdxs={input.optionIdxs || null}
                      disabled={input.disabled}
                    />
                  );
                default:
                  return (
                    <FormInput
                      placeholder={input.placeholder}
                      type={input.type}
                      label={input.label}
                      key={key}
                      changeHandler={(e) => setDocVal(key, e.target.value)}
                      errorMsg={docErrMsg[key]}
                      value={docFormValues[key]}
                      disabled={input.disabled}
                    />
                  );
              }
            })}
          </div>
        </FormContainer>
      </InfoContainer>
      {/* <WastePricingContainer>
        <FlexContainer className="justify-between">
          <FormTitle>Pickup Pricing (&#8358;)</FormTitle>
          <CategorySelect
            setCategories={setBodyCategories}
            options={categories}
            optionIdxs={categoryIdxs.current}
            existingCategories={catValues}
          />
        </FlexContainer>
        <FormContainer>
          {categories?.length > 0 || fetchedCategories?.length > 0 ? (
            <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
              {Object.entries(catValues).map(([key, input]) => {
                return(
                  <FormInput
                  placeholder={"Price rate for 1kg"}
                  label={input.label}
                  key={key}
                  changeHandler={(e) => setCatVal(key, e.target.value)}
                  errorMsg={catErrMsg[key]}
                  value={catFormValues[key] || input?.value}
                  disabled={false}
                />
                )
              })}
            </div>
          ) : (
            <p className="w-full text-center text-lg text-body">
              No category has been added
            </p>
          )}
        </FormContainer>
      </WastePricingContainer> */}


      <section>
        <TabContainer>
          <Tabs defaultActiveKey="0" onChange={callback}>

                <TabPane key={'0'} tab={'Household'}>
                  <WastePricingContainer>
                    <FlexContainer className="justify-between">
                      <FormTitle>Pickup Pricing (&#8358;)</FormTitle>
                      <CategorySelect
                        setCategories={setBodyCategories}
                        options={categories}
                      />
                    </FlexContainer>
                    <FormContainer>
                      {Object.keys(catValues)?.length > 0 ? (
                        <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
                          {/* {categories?.map(({ text, value }) => ( */}
                          {Object.entries(catValues).map(([key, input]) => (
                            <FormInput
                              placeholder={"Price rate for 1kg"}
                              label={input.label}
                              key={key}
                              changeHandler={(e) => setCatVal(key, e.target.value || '')}
                              errorMsg={catErrMsg[key]}
                              value={catFormValues[key]|| ''}
                              disabled={false}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="w-full text-center text-lg text-body">
                          No category has been added
                        </p>
                      )}
                      {/* <p className="w-full text-center text-lg text-body">
                        No category has been added
                      </p> */}
                    </FormContainer>
                  </WastePricingContainer>
            
                  {/* DROP OFF PRICING */}
                  <WastePricingContainer>
                    <FlexContainer className="justify-between">
                      <FormTitle>Drop-Off Pricing</FormTitle>
                      <Button type="outline" height="40px" 
                        // onClick={() => toggle(showDrop)}
                        onClick={() => {
                          if ( bodyCategories.length > 0 && activated){
                            setActivate(false)
                            setShow1(true);
                          }else if (bodyCategories.length < 1){
                              setShow(true);
                            }
                            else{
                              setActivate(true)
                            }
                            }
                          }
                        >
                          {activated? 'Deactivate Service':'Activate Service'}
                        </Button>
            
                        <Modal show={show1} close={()=>setShow1(false)} >  
                        <div className="space-y-6 flex flex-col justify-center items-center">
                          <img src="/assets/images/mark.png" alt="" />
            
                          <div>
                              <p className="text-[#1D2739] text-[14px] font-[600] font-int text-center ">You’ve deactivated drop-off service. You can <br />activate it when you modify this organization.</p>              
                          </div>
            
                            <button onClick={()=>setShow1(false)} className="text-[#005700] text-[14px] font-[600] font-int text-center ">Ok, noted</button>              
                        </div>  
            
                        </Modal>
                        <Modal show={show} close={()=>setShow(false)} >  
                        <div className="space-y-6 flex flex-col justify-center items-center">
                          <img src="/assets/images/oops.png" alt="" />
            
                          <div>
                            <p className="text-[#98A2B3] text-[12px] font-[400] font-int text-center ">Oops!</p>
                              <p className="text-[#1D2739] text-[14px] font-[600] font-int text-center ">You need to add waste categories before <br />activating drop-off service</p>              
                          </div>
            
                            <button onClick={()=>setShow(false)} className="text-[#005700] text-[14px] font-[600] font-int text-center ">Ok, noted</button>              
                        </div>  
            
                        </Modal>
            
                    </FlexContainer>
                    <FormContainer>
                        {activated? 
                          (
                            <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
                            <FormInput
                            placeholder={"Percentage Price per KG"}
                            type="text"
                            label={'Drop-off Percentage Price (%)'}
                            changeHandler={(e) => setDropVal('dropOff', e.target.value)}
                            errorMsg={dropErrMsg.dropOff}
                            value={`${dropFormValues?.dropOff || ''}`}
                            disabled={false}
                          />                  
                          </div>
            
                      ) : (
                        <p className="w-full text-center text-lg text-body">
                          Drop-off is yet to be activated for this organization
                        </p>
                      )}
                    </FormContainer>
                  </WastePricingContainer>
                </TabPane>

                {/* AGGREGATOR TAB */}
                <TabPane key={'1'} tab={'Aggregator'}>
                  <WastePricingContainer>
                    <FlexContainer className="justify-between">
                      <FormTitle>Pickup Pricing (&#8358;)</FormTitle>
                      <CategorySelect
                        setCategories={setAggCategories}
                        options={categories}
                      />
                    </FlexContainer>
                    <FormContainer>
                      {Object.keys(aggCatValues)?.length > 0 ? (
                        <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
                          {/* {categories?.map(({ text, value }) => ( */}
                          {Object.entries(aggCatValues).map(([key, input]) => (
                            <FormInput
                              placeholder={"Price rate for 1kg"}
                              label={input.label}
                              key={key}
                              changeHandler={(e) => setAggCatVal(key, e.target.value || '')}
                              errorMsg={aggCatErrMsg[key]}
                              value={aggCatFormValues[key]|| ''}
                              disabled={false}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="w-full text-center text-lg text-body">
                          No category has been added
                        </p>
                      )}
                      {/* <p className="w-full text-center text-lg text-body">
                        No category has been added
                      </p> */}
                    </FormContainer>
                  </WastePricingContainer>
            
                  {/* DROP OFF PRICING */}
                  <WastePricingContainer>
                    <FlexContainer className="justify-between">
                      <FormTitle>Drop-Off Pricing</FormTitle>
                      <Button type="outline" height="40px" 
                        // onClick={() => toggle(showDrop)}
                        onClick={() => {
                          if ( aggCategories.length > 0 && aggActivated){
                            setAggActivate(false)
                            setShow1(true);
                          }else if (aggCategories.length < 1){
                              setShow(true);
                            }
                            else{
                              setAggActivate(true)
                            }
                            }
                          }
                        >
                          {aggActivated? 'Deactivate Service':'Activate Service'}
                        </Button>
            
                        <Modal show={show1} close={()=>setShow1(false)} >  
                        <div className="space-y-6 flex flex-col justify-center items-center">
                          <img src="/assets/images/mark.png" alt="" />
            
                          <div>
                              <p className="text-[#1D2739] text-[14px] font-[600] font-int text-center ">You’ve deactivated Aggregator drop-off service. You can <br />activate it when you modify this organization.</p>              
                          </div>
            
                            <button onClick={()=>setShow1(false)} className="text-[#005700] text-[14px] font-[600] font-int text-center ">Ok, noted</button>              
                        </div>  
            
                        </Modal>
                        <Modal show={show} close={()=>setShow(false)} >  
                        <div className="space-y-6 flex flex-col justify-center items-center">
                          <img src="/assets/images/oops.png" alt="" />
            
                          <div>
                            <p className="text-[#98A2B3] text-[12px] font-[400] font-int text-center ">Oops!</p>
                              <p className="text-[#1D2739] text-[14px] font-[600] font-int text-center ">You need to add waste categories before <br />activating Aggregator drop-off service</p>              
                          </div>
            
                            <button onClick={()=>setShow(false)} className="text-[#005700] text-[14px] font-[600] font-int text-center ">Ok, noted</button>              
                        </div>  
            
                        </Modal>
            
                    </FlexContainer>
                    <FormContainer>
                        {aggActivated? 
                          (
                            <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
                              <FormInput
                              placeholder={"Percentage Price per KG"}
                              type="text"
                              label={'Drop-off Percentage Price (%)'}
                              changeHandler={(e) => setAggDropVal('dropOff', e.target.value)}
                              errorMsg={aggDropErrMsg.dropOff||''}
                              value={`${aggDropFormValues.dropOff|| ''}`}
                              disabled={false}
                            />                  
                            </div>
            
                      ) : (
                        <p className="w-full text-center text-lg text-body">
                          Drop-off is yet to be activated for this organization
                        </p>
                      )}
                    </FormContainer>
                  </WastePricingContainer>
                </TabPane>

                {/* INDUSTRIAL TAB */}
                <TabPane key={'2'} tab={'Industrial'}>
                  <WastePricingContainer>
                    <FlexContainer className="justify-between">
                      <FormTitle>Pickup Pricing (&#8358;)</FormTitle>
                      <CategorySelect
                        setCategories={setAggCategories}
                        options={categories}
                      />
                    </FlexContainer>
                    <FormContainer>
                      {Object.keys(aggCatValues)?.length > 0 ? (
                        <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
                          {/* {categories?.map(({ text, value }) => ( */}
                          {Object.entries(aggCatValues).map(([key, input]) => (
                            <FormInput
                              placeholder={"Price rate for 1kg"}
                              label={input.label}
                              key={key}
                              changeHandler={(e) => setAggCatVal(key, e.target.value || '')}
                              errorMsg={aggCatErrMsg[key]}
                              value={aggCatFormValues[key]|| ''}
                              disabled={false}
                            />
                          ))}
                        </div>
                      ) : (
                        <p className="w-full text-center text-lg text-body">
                          No category has been added
                        </p>
                      )}
                      {/* <p className="w-full text-center text-lg text-body">
                        No category has been added
                      </p> */}
                    </FormContainer>
                  </WastePricingContainer>
            
                  {/* DROP OFF PRICING */}
                  <WastePricingContainer>
                    <FlexContainer className="justify-between">
                      <FormTitle>Drop-Off Pricing</FormTitle>
                      <Button type="outline" height="40px" 
                        // onClick={() => toggle(showDrop)}
                        onClick={() => {
                          if ( indCategories.length > 0 && indActivated){
                            setIndActivate(false)
                            setShow1(true);
                          }else if (indCategories.length < 1){
                              setShow(true);
                            }
                            else{
                              setIndActivate(true)
                            }
                            }
                          }
                        >
                          {indActivated? 'Deactivate Service':'Activate Service'}
                        </Button>
            
                        <Modal show={show1} close={()=>setShow1(false)} >  
                        <div className="space-y-6 flex flex-col justify-center items-center">
                          <img src="/assets/images/mark.png" alt="" />
            
                          <div>
                              <p className="text-[#1D2739] text-[14px] font-[600] font-int text-center ">You’ve deactivated Aggregator drop-off service. You can <br />activate it when you modify this organization.</p>              
                          </div>
            
                            <button onClick={()=>setShow1(false)} className="text-[#005700] text-[14px] font-[600] font-int text-center ">Ok, noted</button>              
                        </div>  
            
                        </Modal>
                        <Modal show={show} close={()=>setShow(false)} >  
                        <div className="space-y-6 flex flex-col justify-center items-center">
                          <img src="/assets/images/oops.png" alt="" />
            
                          <div>
                            <p className="text-[#98A2B3] text-[12px] font-[400] font-int text-center ">Oops!</p>
                              <p className="text-[#1D2739] text-[14px] font-[600] font-int text-center ">You need to add waste categories before <br />activating Aggregator drop-off service</p>              
                          </div>
            
                            <button onClick={()=>setShow(false)} className="text-[#005700] text-[14px] font-[600] font-int text-center ">Ok, noted</button>              
                        </div>  
            
                        </Modal>
            
                    </FlexContainer>
                    <FormContainer>
                        {indActivated? 
                          (
                            <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
                              <FormInput
                              placeholder={"Percentage Price per KG"}
                              type="text"
                              label={'Drop-off Percentage Price (%)'}
                              changeHandler={(e) => setIndDropVal('dropOff', e.target.value)}
                              errorMsg={indDropErrMsg.dropOff||''}
                              value={`${indDropFormValues.dropOff|| ''}`}
                              disabled={false}
                            />                  
                            </div>
            
                      ) : (
                        <p className="w-full text-center text-lg text-body">
                          Drop-off is yet to be activated for this organization
                        </p>
                      )}
                    </FormContainer>
                  </WastePricingContainer>
                </TabPane>

          </Tabs>
        </TabContainer>
      </section>

      {/* <WastePricingContainer>
        <FlexContainer className="justify-between">
          <FormTitle>Drop-Off Pricing</FormTitle>
          <Button type="outline" height="40px" 
            // onClick={() => toggle(showDrop)}
            onClick={() => {
              if ( bodyCategories.length > 0 && activated){
                setActivate(false)
                setShow1(true);
              }else if (bodyCategories.length < 1){
                  setShow(true);
                }
                else{
                  setActivate(true)
                }
                }
              }
            >
              {activated? 'Deactivate Service':'Activate Service'}
            </Button>

            <Modal show={show1} close={()=>setShow1(false)} >  
            <div className="space-y-6 flex flex-col justify-center items-center">
              <img src="/assets/images/mark.png" alt="" />

              <div>
                  <p className="text-[#1D2739] text-[14px] font-[600] font-int text-center ">You’ve deactivated drop-off Service. You can <br />activate it when you modify this organization.</p>              
              </div>

                <button onClick={()=>setShow1(false)} className="text-[#005700] text-[14px] font-[600] font-int text-center ">Ok, noted</button>              
            </div>  

            </Modal>
            <Modal show={show} close={()=>setShow(false)} >  
            <div className="space-y-6 flex flex-col justify-center items-center">
              <img src="/assets/images/oops.png" alt="" />

              <div>
                <p className="text-[#98A2B3] text-[12px] font-[400] font-int text-center ">Oops!</p>
                  <p className="text-[#1D2739] text-[14px] font-[600] font-int text-center ">You need to add waste categories before <br />activating drop-off service</p>              
              </div>

                <button onClick={()=>setShow(false)} className="text-[#005700] text-[14px] font-[600] font-int text-center ">Ok, noted</button>              
            </div>  

            </Modal>

        </FlexContainer>
        <FormContainer>
            {activated? 
              (
                <div className="w-full grid md:grid-cols-2 gap-x-10 mt-5">
                  <FormInput
                  placeholder={"Percentage Price per KG"}
                  type="text"
                  label={'Drop-off Percentage Price (%)'}
                  changeHandler={(e) => setDropVal('dropOff', e.target.value)}
                  errorMsg={dropErrMsg.dropOff}
                  value={`${dropFormValues?.dropOff || ''}`}
                  disabled={false}
                />                  
                </div>

          ) : (
            <p className="w-full text-center text-lg text-body">
              Waste Drop-off Service is not acivated for this Organization
            </p>
          )}
        </FormContainer>
      </WastePricingContainer> */}

      <div className="mt-20 ">
        <Button
          width=""
          submit
          onClick={() => handleSubmit()}
          // disabled={!!valAggregator(errorMsgs, docErrMsg, catErrMsg)}
        >
          Modify Accounts
        </Button>
      </div>
      <Br />
    </SetupOrganizationContainer>
  );
};

export default ModifyOrganization;
