import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { toggleStatusAggregator } from "../../store/actions";
import { ApproveNewAggregators, DeleteNewAggregators } from "../../store/actions/dashboardAction";
import { set } from "lodash";
import Modal from "./modal";

const ModalBackground = styled.div`
  width: 90px;
  background: #fff;
  position: absolute;
  border-radius: 10px;
  z-index: 999;
  right: 0px;
  .menu:hover {
    color: green;
  }
  .modalContainer {
    position: relative;
  }
`;

function TotalOrgActionModal({
  setModalOpen,
  data,
  onRefresh,
  type,
  link,
  rejectHandler,
  disableHandler,
  enableHandler,
  approveHandler
}) {


  return (
    <>
      <div className="modalContainer">
        <ModalBackground>
          <Menu style={{ height: "100px" }}>
            {
              type==='approved' &&
              <>
                <Menu.Item key="0">
                  <Link to={link} className="menu" >
                    {/* {currentStatus} */}
                    View
                  </Link>
                </Menu.Item>
                <Menu.Item key="1">
                  <Link to="#" className="menu" onClick={disableHandler}>
                    {/* {currentStatus} */}
                    Disable
                  </Link>
                </Menu.Item>             
              </>

            }
            {
              (type==='new' || type==='rejected') &&
              <Menu.Item key="2">
                <Link to="#" className="menu" onClick={approveHandler}>
                  {/* {currentStatus} */}
                  Approve
                </Link>
              </Menu.Item>
            }
            {
              type==='new' &&
              <Menu.Item key="3">
                <Link to="#" className="menu" onClick={rejectHandler}>
                  {/* {currentStatus} */}
                  Reject
                </Link>
              </Menu.Item>
            }
            {
              type==='disabled' &&
              <Menu.Item key="4">
                <Link to="#" className="menu" onClick={enableHandler}>
                  {/* {currentStatus} */}
                  Enable
                </Link>
              </Menu.Item>
            }


          </Menu>
        </ModalBackground>
      </div>
    </>
  );
}

export default TotalOrgActionModal;
