import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import StyledButton from "../../components/UI/btn";
import NewWasteCategoryModal from "../../components/wasteCategory/NewWasteCategoryModal";
// import WasteSubCategoryCard from "../../components/wasteCategory/wasteSubCategoryCard";
import { deleteCategory, getCategory } from "../../store/actions";
import { claimPermissions } from "../../utils/constants";
import Accordion, { DropButton, DropdownContainer } from "../../components/UI/Accordion";
import { Space } from "antd";
import DataTable from "../../components/UI/Table";
import UpdateMainCategoryModal from "../../components/wasteCategory/UpdateMainCategoryModal";
import DeleteModal from "../../components/common/DeleteModal";
import AddSubCategoryModal from "../../components/wasteCategory/AddSubCategoryModal";
import AltMenu from '../../assets/images/menu-icon.svg'
import { IoAddCircle } from "react-icons/io5";
import { FaEdit, FaEye } from "react-icons/fa";
import { FiDelete } from "react-icons/fi";
import Button from "../../components/UI/button";
import { RiDeleteBin2Line } from "react-icons/ri";
import { Link } from "react-router-dom/cjs/react-router-dom";

const WasteCategoryContainer = styled.div`
  display: grid;
  gap: 20px;
`;
const WasteCategoryHeader = styled.div`
  ${tw`flex self-end justify-self-end`}
`;
const WasteCategoryList = styled.div`
  ${tw`bg-white`}
`;

const WasteCategory = () => {

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { category, meta } = useSelector((state) => state.wasteCategory);
  const [bodyData, setBodyData] = useState();
  const user_claims = JSON.parse(localStorage.getItem('Claims'))
  const [show, setShow] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showDeleteModal, setDeleteModal] = useState(false);
    const [rowInfo, setRowInfo] = useState([]);
  const date = new Date();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [paginationData, setPaginationData] = useState();

  console.log(category)

  const deleteHandler = async () => {
    const res = await dispatch(deleteCategory(rowInfo._id));
    if (!res.error) dispatch(getCategory());
  };

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const {
    userInfo: { claims },
  } = useSelector((state) => state.auth);

  const wastePermissions = claims?.claims?.find(
    (claim) => claim.claimId.title === claimPermissions.WASTE_CATEGORY.title
  );

  useEffect(() => {
    if (!category) dispatch(getCategory());
    if (category) {
        setBodyData(category);
        setPaginationData({ ...meta, currentMonth: payload });
      }
  }, [category]);

    const onSearch = async (searchQuery) => {
      // const res = await dispatch(
      //   searchPending({
      //     page: currentPage,
      //     searchQuery: searchQuery || "",
      //   })
      // );
      // if (!res.error) {
      //   const { schedules, ...paginationData } = res.payload.data;
      //   setBodyData(schedules);
      //   setPaginationData({ ...paginationData });
      //   setTotalPages(paginationData.totalPages);
      // }
    };
  
  
    const onFilter = async (date, page = 1) => {
      // const res = await dispatch(
      //   filterPending({
      //     page,
      //     currentMonth: date,
      //   })
      // );
  
      // if (!res.error) {
      //   const { schedules, ...paginationData } = res.payload.data;
      //   setBodyData(schedules);
      //   setPaginationData({ ...paginationData, date });
      //   setTotalPages(paginationData.totalPages);
      // }
    };
  
    const thisMonth = useSelector((state) => state?.schedules);
    const { currentMonthPendingSchedule } = thisMonth;
  
    // const fetchPending = async (page = 1) => {
    //   const res = await dispatch(currentMonthPending({ date: payload, page }));
    //   const { schedules, ...paginationData } = res.payload.data;
    //   setBodyData(schedules);
    //   setPaginationData({ ...paginationData, currentMonth: payload });
    // };
  
    // const fetchPending = async (page) => {
    //   const res = await dispatch(currentMonthPending(payload, (page = 1)));
    //   if (!res.error) {
    //     const { schedules, ...paginationData } = res.payload.data;
    //     setBodyData(schedules);
    //     setPaginationData({ ...paginationData });
    //   }
    // };
  
    const fetchPending = async (page = 1) => {
      // const res = await dispatch(
      //   currentMonthPending({
      //     page,
      //     currentMonth: payload,
      //   })
      // );
      // if (!res.error) {
      //   const { schedules, ...paginationData } = res.payload.data;
      //   setBodyData(schedules);
      //   setPaginationData({ ...paginationData, currentMonth: payload });
      // }
    };
  
    useEffect(() => {
      fetchPending();
    }, []);
  
    const onRefresh = () => {
      dispatch(getCategory())
    };
  
    useEffect(() => {
      onRefresh();
    }, []);

  const columns = [
  
    {
      title: "Waste Category",
      dataIndex: "name",
      key: "name",
      // render: (categories) => (
      //   <span>
      //     {(categories.slice(0, 3) || []).map((waste) => {
      //       return (
      //         <Tag key={waste}>
      //           <Popover content={waste?.name || waste}>
      //             {/* {truncate(waste?.name, 10)} */}
      //           </Popover>
      //         </Tag>
      //       );
      //     })}
      //   </span>
      // ),
    },

    {
      title: "Land Fill",
      dataIndex: "landFillImpact",
      key: "landFillImpact",
    },
    {
      title: "CO2 Impact",
      dataIndex: "co2Impact",
      key: "co2Impact",
    },

    {
      title: "Water Impact",
      dataIndex: "waterImpact",
      key: "waterImpact",
      // render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
    },
    {
      title: "Tree Impact",
      dataIndex: "treeImpact",
      key: "treeImpact",
      // render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
    },
    {
      title: "Energy Impact",
      dataIndex: "energyImpact",
      key: "energyImpact",
      // render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
    },
    // {
    //   title: "Jobs Impact",
    //   dataIndex: "pickUpDate",
    //   key: "pickUpDate",
    //   // render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
    // },

    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <div onClick={()=>setRowInfo(record)} className="flex items-center gap-2">
                    <Link
                        to={{
                          pathname: `/admin/waste_sub_category/${record._id}`,
                          // id: record.key
                        }}
                      >
                      <button className="bg-[f4f4f4] p-1 "><FaEye/></button>  
                        </Link>
                      {
                        user_claims?.edit &&
                        <button className="bg-[f4f4f4] p-1 " onClick={()=>{
                          setShowUpdateModal(true)
                          setShow(false)
                        }}><FaEdit color="brown"/></button>                        
                      }

                      {
                        user_claims?.create &&
                        <button className="bg-[f4f4f4] p-1 " onClick={()=>{
                          setShowAddModal(true)
                          setShow(false)
                        }}><IoAddCircle color="green"/></button>                                           
                      }

                      {
                        user_claims?.delete &&
                        <button className="bg-[f4f4f4] p-1 " onClick={()=>{
                          setDeleteModal(true)
                          setShow(false)
                          }}><RiDeleteBin2Line color="red"/></button>                     
                      }
              </div>
        </Space>
      ),
    },
  ];

  return (
    <>
      <NewWasteCategoryModal
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteModal}
        handleDelete={deleteHandler}
        title={rowInfo.name}
        type="category"
      />
      <UpdateMainCategoryModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        name={rowInfo.name}
        id={rowInfo._id}
        type="category"
      />
      <AddSubCategoryModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        name={rowInfo.name}
        sub = {rowInfo.item}
        wastepicker={rowInfo.wastepicker}
        id={rowInfo._id}
        type="category"
      />

      <WasteCategoryContainer>
        {wastePermissions?.create && (
          <WasteCategoryHeader>
            <StyledButton
              buttonStyle="btn--primary--outline"
              buttonSize="btn--medium"
              className="flex justify-between items-center"
              onClick={() => setShowModal(true)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Create
            </StyledButton>
          </WasteCategoryHeader>
        )}
        <WasteCategoryList>

        <div>
        <DataTable
          data={bodyData}
          columns={columns}
          header
          onSearch={onSearch}
          setCurrentPage={setCurrentPage}
          onFilter={onFilter}
          onRefresh={onRefresh}
          totalPages={totalPages}
          onFetch={fetchPending}
          paginationData={paginationData}
        />
      </div>

        {/* <Accordion permission={wastePermissions} items={category}/> */}
          {/* {category?.map((item, i) => {
            return (
              <Accordion key={i} permission={wastePermissions} items={item}/>

              <WasteCategoryCard
                showDelete={wastePermissions?.delete}
                name={name}
                id={_id}
                key={i}
                value={value}
                wastepicker={wastepicker}
              />
            );
          })} */}
        </WasteCategoryList>
      </WasteCategoryContainer>
    </>
  );
};

export default WasteCategory;

