import React, { useEffect, useRef, useState } from "react";
import DisableModal from "./DisableModal";
import ApproveCollectorModal from "./ApproveCollectorModal";
import TotalOrgActionModal from "./TotalOrgActionModal";

function TotalOrgActions({
  data,
  collectorId,
  onRefresh,
  type,
  rejectHandler,
  enableHandler,
  disableHandler,
  link,
  approveHandler
}) {
  const ref = useRef();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        setModalOpen(false);
      }

      if (modalOpen && ref.current) {
        setModalOpen(false);
      }
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [modalOpen]);

  return (
    <div ref={ref}>
      <button
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <img src="/assets/images/totalVector.svg" alt="" />
      </button>

      {modalOpen && (
        <TotalOrgActionModal
          data={data}
          type={type}
          link={link}
          onRefresh={onRefresh}
          setModalOpen={setModalOpen}
          rejectHandler={rejectHandler}
          disableHandler={disableHandler}
          enableHandler={enableHandler}
          approveHandler={approveHandler}
        />
      )}
    </div>
  );
}

export default TotalOrgActions;
