import { createSlice } from "@reduxjs/toolkit";
import { getCategory, getSubCategory } from "../actions";
// import { getCategory } from "../actions/wasteCategoryAction";

const initialState = {
  category: null,
  subCategories: null,
  meta:null
};

const wasteCategorySlice = createSlice({
  name: "wasteCategorySlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getCategory.fulfilled]: (state, { payload }) => {
      state.category = payload.data.categories;
      state.meta = payload.data.meta;
    },
    [getSubCategory.fulfilled]: (state, { payload }) => {
      state.subCategory = payload.data.categories;
      state.meta = payload.data.meta;
    },
  },
});

const { reducer } = wasteCategorySlice;

export default reducer;
