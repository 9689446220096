import axios from "axios";
import baseAxios from "../core/api/axios/baseAxios";
import { getAllOrganisations } from "../store/actions";

export const newbaseURL = process.env.REACT_APP_NEW_TEST_URL;

export default class ResourceService {
  /****************************
   *
   * getter services
   *
   ****************************/


  static async FetchResourceSummaryData(monthIndex) {
    const res = await axios.get(`${newbaseURL}/v2/admin/marketing/schedules?month=${monthIndex}`
    );
    return res?.data || res;
  }

  static async FetchResourceRecycleMaterial(monthIndex) {
    const res = await axios.get(`${newbaseURL}/v2/admin/marketing/schedules/highest?month=${monthIndex}`
    );
    return res?.data || res;
  }
  static async FetchResourceArea() {
    const res = await axios.get(`${newbaseURL}/v2/lga/`);
    return res?.data || res;
  }

  static async FetchResourceByWasteCollected(data) {
    const {category,id,year}= data
    const res = await axios.get(`${newbaseURL}/v2/admin/marketing/waste?organisationId=${id}&category=${category}&year=${year}`
    );
    return res?.data || res;
  }
  static async FetchResourceByArea(data) {
    const {area, year, status}= data
    const res = await axios.get(`${newbaseURL}/v2/admin/marketing/waste/location?area=${area}&year=${year}&status=${status}`
    );
    return res?.data || res;
  }
  static async FetchResourceByStatus(data) {
    const {area, year, status}= data
    const res = await axios.get(`${newbaseURL}/v2/admin/marketing/waste/location?area=${area}&year=${year}&status=${status}`
    );
    return res?.data || res;
  }



  static async GetAllOrganisations() {
    const res = await axios.get(`${newbaseURL}/v2/organisation`);
    return res?.data || res;
  }



  static async GetAllWasteDetails(data) {
    const {start, end, page} = data
    const res = await axios.get(`${newbaseURL}/v2/admin/marketing/waste/details?page=${page}&limit=5&start=${start}&end=${end}`);
    return res?.data || res;
  }
  static async GetOrgWasteDetails(data) {
    const {start, end, page} = data
    const res = await axios.get(`${newbaseURL}/v2/admin/marketing/waste/organisation?page=${page}&limit=5&start=${start}&end=${end}`);
    return res?.data || res;
  }















  static async getResources(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/admin/resource/all?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}`
    );
    return res?.data || res;
  }
  /****************************
   *
   * creator services
   *
   ****************************/
  static async createResources(data) {
    const res = await baseAxios.post("/v2/admin/resource/create", data);
    return res?.data || res;
  }

  /****************************
   *
   * update servicesw2
   *
   ****************************/
  static async updateResources(resourceId, data) {
    const res = await baseAxios.put(`/v2/admin/resource/update/${resourceId}`, data);

    return res?.data || res;
  }

  /****************************
   *
   * delete services
   *
   ****************************/
  static async deleteResources(resourceId) {
    const res = await baseAxios.delete(`/v2/admin/resource/${resourceId}`);
    return res?.data || res;
  }



}

