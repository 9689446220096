import axios from "axios";
import baseAxios from "../core/api/axios/baseAxios";
import newBaseAxios from "../core/api/axios/newBaseAxios";
export const newbaseURL = "https://api-revamp.pakam.ng/api";

export default class DashboardService {
  /****************************
   *
   * currentMonth services
   *
   ****************************/
  static async currentMonthMarix(date) {
    const res = await baseAxios.get(
      // `/v2/dashboard/matrix?start=${date.start}&end=${date.end}`
      `/v2/dashboard/stats`
    );

    return res?.data || res;
  }

  static async companyMatrix({ start, end }) {
    let url = `/v2/dashboard/stats`;
    // let url = `/v2/dashboard/company/matrix?start=${start}&end=${end}`;
    const res = await baseAxios.get(url);
    return res?.data || res;
  }

  /****************************
   *
   * filter services
   *
   ****************************/
  static async filterMatrix(date) {
    const res = await baseAxios.get(
      `/v2/dashboard/matrix?start=${date.start}&end=${date.end}`
    );
    return res?.data || res;
  }
  /****************************
   *
   * recent-pickup services
   *
   ****************************/
  // `/v2/dashboard/recentpickup?start=${data.start}&end=${data.end}&page=${data.page}`
  static async RecentPickup(data) {
    const res = await baseAxios.get(
      
      `/v2/dashboard/details?type=recentPickups&page=${data.page}`
      // `/v2/admin/schedules/completed?start=${data.start}&end=${data.end}&completionStatus=completed&page=${data.page}`
    );
    return res?.data || res;
  }

  static async filterRecentPickup(page, data) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=recentPickups&filterType=custom&startDate=${data.start}&endDate=${data.end}&page=${page}`
      // `/v2/dashboard/recentpickup?start=${data.start}&end=${data.end}&page=${page}`
    );
    return res?.data || res;
  }
  static async recentPickupSearch(key, page, data) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=recentPickups&filterType=custom&startDate=${data.start}&endDate=${data.end}&searchTerm=${key}&page=${page}`
      // `/v2/dashboard/recentpickup?key=${key}&page=${page}`
    );
    return res?.data || res;
  }

  static async getcompanyRecentPickup(data) {
    const res = await baseAxios.get(
      `/v2/company-schedules?completionStatus=completed&start=${data.start}&end=${data.end}&page=${data.page}`
    );
    return res?.data || res;
  }
  static async companyRecentPickup(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/company-schedules?completionStatus=completed&start=${currentMonth.start}&end=${currentMonth.end}&page=${page}`
    );
    return res?.data || res;
  }

  static async companyPickupSearch(key, page) {
    const res = await baseAxios.get(
      `/v2/company-schedules?completionStatus=completed&key=${key}&page=${page}`
    );
    return res?.data || res;
  }

  /****************************
   *
   * new-users services
   *
   ****************************/
  static async NewUsers(data) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=newUsers&limit=10`
    );
    return res?.data || res;
  }
  static async NewUsersSearch(key, page) {
    const res = await baseAxios.get(
      `/v2/dashboard/newusers?key=${key}&page=${page}`
    );
    return res?.data || res;
  }
  static async filterNewUsers(page, data) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=newUsers&filterType=custom&startDate=${data.start}&endDate=${data.end}`
      // `/v2/dashboard/newusers?start=${data.start}&end=${data.end}&page=${page}`
    );
    return res?.data || res;
  }
  /****************************
   *
   * new-aggregators services
   *
   ****************************/
  static async NewAggregators(data) {
    const res = await newBaseAxios.get(
      `/v2/admin/newAggregators?start=${data.start}&end=${data.end}&page=${data.page}`);
    return res?.data || res;
  }
  
  static async NewAggregatorsSearch(key, page) {
    const res = await baseAxios.get(
      `/v2/dashboard/newAggregators?key=${key}&page=${page}`
    );
    return res?.data || res;
  }
  static async filterNewAggregators(page, data) {
    const res = await baseAxios.get(
      `/v2/dashboard/newAggregators?start=${data.start}&end=${data.end}&page=${page}`
    );
    return res?.data || res;
  }
  /****************************
   *
   * new-waste pickers services
   *
   ****************************/

  static async NewWastePickers(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/dashboard/newusers?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}`
    );
    return res?.data || res;
  }
  static async NewWastePickersSearch(key) {
    const res = await baseAxios.get(`/v2/dashboard/newusers?key=${key}`);
    return res?.data || res;
  }

  static async approveNewAggregators(data) {
    const res = await newBaseAxios.put(`/v2/admin/collector/approve/${data.pickerId}`);

    return res?.data || res;
  }
  static async deleteNewAggregators(data) {
    const res = await newBaseAxios.delete(`/v2/admin/collector/delete/${data.pickerId}`);

    return res?.data || res;
  }
  
}


