import React, { useState, useEffect } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import useForm from "../../hooks/useForm";
import { createCategory, getCategory } from "../../store/actions";
import StyledButton from "../UI/btn";
import modal from "../UI/modal";
import FormInput from "../auth/FormInput";
import { FlexContainer, TitleText } from "../styledElements";
import { MdDescription } from "react-icons/md";
import Select from "../UI/select";
import MultiSelect from "../UI/multiSelect";

const Modal = styled(modal)`
  ${tw``};
`;

const optionData=[
  {
    text:'DropOff',
    value:'showOnDropoff'
  },
  {
    text:'PickUp',
    value:'showOnPickup'
  },
]


const WasteCategoryItemImg = styled.img`
  ${tw`cursor-pointer`}
`;

const initData = {
  name: {
    label: "Category",
    value: "",
    placeholder: "Type Waste Category",
    type: "text",
    rules: [(v) => !!v || "Waste Category is required"],
  },
  desc: {
    label: "Description",
    value: "",
    placeholder: "Description",
    type: "text",
    rules: [(v) => !!v || "Waste Category is required"],
  },
  permissions: {
    label: "Permissions",
    optionIdxs: [],
    type: "multiselect",
    options: optionData,
    // rules: [(v) => !!v || ""],
  },
};
const impacts = {
  land: {
    label: "Land Fill",
    value: "",
    placeholder: "Type Waste Category",
    type: "number",
    rules: [(v) => !!v || "Waste Category is required", (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",],
  },
  co2: {
    label: "CO2",
    value: "",
    placeholder: "Description",
    type: "number",
    rules: [(v) => !!v || "Waste Category is required", (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",,],
  },
  water: {
    label: "Water",
    value: "",
    placeholder: "Description",
    type: "number",
    rules: [(v) => !!v || "Waste Category is required", (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",],
  },
  tree: {
    label: "Tree",
    value: "",
    placeholder: "Description",
    type: "number",
    rules: [(v) => !!v || "Waste Category is required", (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",],
  },
  energy: {
    label: "Energy",
    value: "",
    placeholder: "Description",
    type: "number",
    rules: [(v) => !!v || "Waste Category is required", (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",],
  },
  job: {
    label: "job",
    value: "",
    placeholder: "Description",
    type: "number",
    rules: [(v) => !!v || "Waste Category is required", (v) => /^[0-9]{1,10}$/g.test(v) || "▲ Price must be a number",],
  },

};
const subs = {
  subcat: [
    {
      sub_name: {
        label: "Subcategory Name",
        value: "",
        placeholder: "Type Subcategory Name",
        type: "text",
        rules: [(v) => !!v || "Subcategory Name is required"],
      },
      sub_desc: {
        label: "Subcategory Description",
        value: "",
        placeholder: "Enter Subcategory description",
        type: "text",
        rules: [(v) => !!v || "Subcategory Name is required"],
      },
      country: {
        label: "Country",
        optionIdx: null,
        type: "select",
        options: [],
        rules: [(v) => !!v || "Country is required"],
      },
      showOnPickup: {
        label: "Show on Pickup",
        optionIdx: null,
        type: "select",
        options: optionData,
        rules: [(v) => !!v || "Country is required"],
      },
      wastepicker: {
        label: "Waste Picker Price",
        value: "",
        placeholder: "Type Waste Picker Price",
        type: "number", // You can set the type as needed
        rules: [(v) => !!v || "Waste Picker Price is required"],
      },
    },
  ],
};


const NewWasteCategoryModal = ({
  data = initData,
  showModal = false,
  setShowModal = {},
}) => {
  const {
    app: { error },
  } = useSelector((state) => state);
  const { setValue, formValues, errorMsgs } = useForm(data);
  const { setValue: setImpactValues, formValues: impactFormValues, errorMsgs:impactErrMsg } = useForm(impacts);
  const { setValue: setSubValues, formValues: subFormValues, errorMsgs:subErrMsg } = useForm(subs);
  const [showPostModal, setPostModal] = useState(false);
  const dispatch = useDispatch();

  const createCategoryHandler = async () => {
    // setShowModal(false);
    const categoryData = {
    "name":formValues.name,
    "description":formValues.desc,
    "icon":"https://res.cloudinary.com/dosr2abno/image/upload/fl_preserve_transparency/v1713526114/Aluminium_Can_ayl4d3.jpg?_s=public-apps",
    "landFillImpact":Number(impactFormValues.land),
    "co2Impact":Number(impactFormValues.co2),
    "waterImpact":Number(impactFormValues.water),
    "treeImpact":Number(impactFormValues.tree),
    "energyImpact":Number(impactFormValues.energy),
    "jobsImpact":Number(impactFormValues.job),
    "showOnPickUp":formValues.permissions.includes('showOnPickup'),
    "showOnDropOff":formValues.permissions.includes('showOnDropoff'),
    "items":subFormValues.subcat
}
    // const categoryData = {
    //   name: formValues.name,
    //   subCategory: formValues.subcategories?.map((item)=>item.name),
    // };
 console.log(categoryData)
    const res = await dispatch(createCategory(categoryData));
    if (!res.error) dispatch(getCategory());
    setPostModal(true);
  };


  // useEffect(()=>{
  //   if (!showModal){
  //     setValue('name', '')
  //     setValue('subcategories', []);
  //   }
  // }, [showModal])


  const handleAddSubcategory = () => {
    setSubValues('subcat', [...subFormValues?.subcat, { name: '',description: '', icon:"", showOnDropOff:"", showOnPickUp:'', }]);
  };

  const handleRemoveSubcategory = (indexToRemove) => {
    const updatedSubcategories = subFormValues?.subcat.filter((_, index) => index !== indexToRemove);
    setSubValues('subcat', updatedSubcategories);
  };

  // const handleChangeSubcategory = (index, key, value) => {
  //   console.log(subFormValues)
  //   const updatedSubcategories = [...subFormValues?.subcategories];
  //   console.log(updatedSubcategories)
  //   updatedSubcategories[index][key] = value;
  //   setSubValues('subcategories', updatedSubcategories);
  // };

  const handleChangeSubcategory = (index, key, value) => {
    const updatedSubcategories = subFormValues.subcat.map((sub, i) =>
      i === index ? { ...sub, [key]:  value  } : sub
    );
    setSubValues("subcat", updatedSubcategories);
  };
  console.log(subFormValues, subFormValues.subcat.length)

  return (
    <>
      <Modal
        color={error ? "red" : "#005700"}
        type="postAction"
        show={showPostModal}
        close={() => setPostModal(false)}
      >
        {!error ? "Waste Category created successfully" : error}
      </Modal>
      <Modal show={showModal} close={() => setShowModal(false)} width="40rem">
        <FlexContainer className="justify-between mb-4">
          <TitleText>Create Waste Category</TitleText>
          <StyledButton
            buttonSize="btn--medium"
            onClick={() => setShowModal(false)}
          >
            close
          </StyledButton>
        </FlexContainer>
        <div className="flex flex-col">
        {Object.entries(data).map(([key, input]) => {
                switch (input.type) {
                  case "select":
                    return (
                      <Select
                        key={key}
                        width="100%"
                        height="3.5rem"
                        plain={true}
                        options={input.options}
                        label={input.label}
                        title={input.label}
                        changeHandler={(v) => setValue(key, v)}
                        optionIdx={input.optionIdx !== null && input.optionIdx}
                        disabled={input.disabled}
                        checkbox={input.checkbox}
                      />
                    );
                  case "multiselect":
                    return (
                      <MultiSelect
                        key={key}
                        width="100%"
                        height="3.5rem"
                        plain={true}
                        options={input.options}
                        label={input.label}
                        title={input.title || input.label}
                        changeHandler={(v) => setValue(key, v)}
                        optionIdxs={input.optionIdxs || null}
                        disabled={input.disabled}
                      />
                    );
                  default:
                    return (
                      <FormInput
                        placeholder={input.placeholder}
                        type={input.type}
                        label={input.label}
                        key={key}
                        changeHandler={(e) => setValue(key, e.target.value)}
                        errorMsg={errorMsgs[key]}
                        value={formValues[key]}
                        disabled={input.disabled}
                      />
                    );
                }
              })}
        {/* {Object.entries(data).map(([key, input]) => (
              <div className=" md:gap-x-10">
                <FormInput
                  placeholder={input.placeholder}
                  type={input.type}
                  label={input.label}
                  key={key}
                  changeHandler={(e) => setValue(key, e.target.value)}
                  errorMsg={errorMsgs[key]}
                  value={formValues[key]}
                  disabled={input.disabled}
                />
              </div>
          ))} */}

          <p className="font-bold ">Impacts</p>
          <div className="w-full mt-2 grid grid-cols-2 md:grid-cols-3">
            
            {Object.entries(impacts).map(([key, input]) => (
              <div className="w-[85%] md:gap-x-10">
                <FormInput
                  placeholder={input.placeholder}
                  type={input.type}
                  label={input.label}
                  key={key}
                  changeHandler={(e) => setImpactValues(key, e.target.value)}
                  errorMsg={impactErrMsg[key]}
                  value={impactFormValues[key]}
                  disabled={input.disabled}
                />
              </div>
          ))}
          </div>

          <p className="font-bold ">Subcategories</p>
          <div className="w-full mt-2 grid grid-cols-1 ">
            
            {subFormValues?.subcat.length > 0 && subFormValues?.subcat?.map((subcategory, index) => (

              <div key={index} className="flex gap-4">
                <div className="grid grid-cols-3 gap-4">
                  <FormInput
                    placeholder={'Enter catergory name'}
                    type={'text'}
                    label={'Name'}
                    key={`sub_name-${index}`}
                    height="3.5rem"
                    changeHandler={(e) => handleChangeSubcategory(index, 'name', e.target.value)}
                    errorMsg={subErrMsg['sub_name']}
                    value={subcategory.name.value}
                  />
                  <FormInput
                    placeholder={'Enter catergory desc'}
                    type={'text'}
                    label={'Description'}
                    key={`sub_desc-${index}`}
                    height="3.5rem"
                    changeHandler={(e) => handleChangeSubcategory(index, 'description', e.target.value)}
                    errorMsg={subErrMsg['sub_desc']}
                    value={subcategory.description.value}
                  />
                    <MultiSelect
                          width="100%"
                          height="3.5rem"
                          plain={true}
                          options={optionData}
                          label={'Permission'}
                          title={'Show on'}
                          changeHandler={(e) => {
                            handleChangeSubcategory(index, 'showOnPickUp', e.includes('showPickup'));
                            handleChangeSubcategory(index, 'showOnDropOff', e.includes('showDropoff'))
                          }}
                          // optionIdx={input.optionIdx !== null && input.optionIdx}
                          // disabled={input.disabled}
                          checkbox={true}
                        />
                </div>

                {/* <div className="flex items-center gap-3">
                  <FormInput
                    placeholder={data.subcategories[0].wastepicker.placeholder}
                    type={data.subcategories[0].wastepicker.type}
                    label={data.subcategories[0].wastepicker.label}
                    key={`wastepicker-${index}`}
                    height="3.5rem"
                    changeHandler={(e) => handleChangeSubcategory(index, 'wastepicker', e.target.value)}
                    errorMsg={errorMsgs['wastepicker']}
                    value={subcategory.wastepicker}
                  /> */}
                  <WasteCategoryItemImg
                    src="/assets/images/redbin.svg"
                    alt="delete-icon"
                    onClick={() => {
                      handleRemoveSubcategory(index);
                    }}
                  />              
              </div> 


          
            ))}
          </div>



          <div className="max-w-content space-y-6">
            <StyledButton
              buttonSize="btn--medium"
              buttonStyle="btn--primary--outline"
              onClick={handleAddSubcategory}
            >
              <FiPlusCircle size={"20"} />
              Add Subcategory
            </StyledButton>
            <StyledButton
              buttonSize="btn--medium"
              buttonStyle="btn--primary"
              onClick={createCategoryHandler}
              // disabled={formValues.name.length === 0 || formValues.subcategories.length < 1}
            >
              Done
            </StyledButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewWasteCategoryModal;


