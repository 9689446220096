import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import StyledButton from "../../components/UI/btn";
// import { GrAddCircle } from "react-icons/gr";
import { Space, Tag } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "../../components/UI/Table";
import { getAwaitingOrganisations, getDisabledOrganisations, getOrganisations, getRejectedOrganisations, searchOrganisations, updateOrganisationStatus } from "../../store/actions";
import { claimPermissions } from "../../utils/constants";
import Tabcontent from "../../components/UI/TabContent";
import { DropButton, DropdownContainer } from "../../components/UI/Accordion";
import { Vector } from "../Dashbord";
import TotalOrgActions from "../../components/UI/TotalOrgActions";
import Modal from "../../components/UI/modal";


const OrganizationContainer = styled.div`
  display: grid;
  grid-template-coloumns: auto 1fr;
  gap: 20px;
`;

const OrganizationHeader = styled.div`
  ${tw`flex self-end justify-self-end`}
`;
const TotalOrganization = () => {
    const dispatch = useDispatch();
  const {
    organisation: { organisations, searchOrg },
    auth: {
      userInfo: { claims },
    },
  } = useSelector((state) => state);

  const [fetchedOrgs, setFetchedOrgs] = useState([]);
  const [disabledOrgs, setDisabledOrgs] = useState([]);
  const [rejectedOrgs, setRejectedOrgs] = useState([]);
  const [awaintingOrgs, setAwaintingOrgs] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedInfo, setSelectedInfo] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const filterVal = query.get("start");
    const [message, setMessage] = useState('')
    const [showPostModal, setPostModal] = useState(false)
    const [error, setError] = useState(false)

  const date = new Date();
  const currentMonth = {
    start: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format(
      "YYYY-MM-DD"
    ),
    end: moment(new Date(date.getFullYear(), date.getMonth() + 1, 1)).format(
      "YYYY-MM-DD"
    ),
  };

  const d = new Date();
  d.setDate(d.getDate());
  const payload = {
    start: "2010-01-01",
    end: d,
  };

  const orgPermissions = claims?.claims?.find(
    (claim) => claim.claimId.title === claimPermissions.ORGANISATION.title
  );
  const [paginationData, setPaginationData] = useState();
  const [awaitingPaginationData, setAwaitingPaginationData] = useState();
  const [disabledPaginationData, setDisabledPaginationData] = useState();
  const [rejectedPaginationData, setRejectedPaginationData] = useState();

  // useEffect(() => {
  //   const d = new Date();
  //   d.setDate(d.getDate());
  //   const payload = {
  //     start: "2010-01-01",
  //     end: d,
  //   };
  //   dispatch(getOrganisations({ currentMonth: payload, page: 1 }))
  //     .unwrap()
  //     .then((res) => {
  //       const { organisations, ...paginationData } = res.data;
  //       setPaginationData(paginationData);
  //       setFetchedOrgs(organisations);
  //     });
  //   setFetchedOrgs(organisations?.organisations);
  // }, []);

  useEffect(()=>{
    onRefresh()
  }, [])

  const onRefresh = () => {
    fetchAll();
    fetchAllDisabled();
    fetchAllRejected();
    fetchAllAwaiting();
  };

  const onFilter = async (date, page = 1) => {
    const res = await dispatch(
      getOrganisations({
        page,
        currentMonth: date,
      })
    );
    if (!res.error) {
      const { organisations, ...paginationData } = res.payload.data;
      setFetchedOrgs(organisations);
      setPaginationData({ ...paginationData, date });
      setTotalPages(paginationData.totalPages);
    }
  };

  const onSearch = async (key, page = 1) => {
    const res = await dispatch(
      searchOrganisations({
        key,
        page,
      })
    );
    if (!res.error) {
      const { organisations, ...paginationData } = res.payload.data;
      setFetchedOrgs(organisations);
      setPaginationData({ ...paginationData, key });
      setTotalPages(paginationData.totalPages);
    }
  };

  const fetchAll = async (page = 1) => {
    const res = await dispatch(
      getOrganisations({
        currentMonth: payload,
        page,
      })
    );

    if (!res.error) {
      const { organisations, ...paginationData } = res.payload.data;
      setFetchedOrgs(organisations);
      setPaginationData({ ...paginationData, date: payload });
    }
  };

  const fetchAllDisabled = async (page = 1) => {
    const res = await dispatch(
      getDisabledOrganisations({
        currentMonth: payload,
        page,
      })
    );
    if (!res.error) {
      const { organisations, ...paginationData } = res.payload.data;
      setDisabledOrgs(organisations);
      setDisabledPaginationData({ ...paginationData, date: payload });
    }
  };
  const fetchAllAwaiting = async (page = 1) => {
    const res = await dispatch(
      getAwaitingOrganisations({
        currentMonth: payload,
        page,
      })
    );
    if (!res.error) {
      const { organisations, ...paginationData } = res.payload.data;
      setAwaintingOrgs(organisations);
      setAwaitingPaginationData({ ...paginationData, date: payload });
    }
  };
  const fetchAllRejected = async (page = 1) => {
    const res = await dispatch(
      getRejectedOrganisations({
        currentMonth: payload,
        page,
      })
    );
    if (!res.error) {
      const { organisations, ...paginationData } = res.payload.data;
      setRejectedOrgs(organisations);
      setRejectedPaginationData({ ...paginationData, date: payload });
    }
  };

  const handleEnable = async(id) =>{
    const data ={
      id,
      payload:{
        action:'enable'
      }
    }
        const res = await dispatch(
          updateOrganisationStatus(data)
        );
        if (!res.error) {
          console.log(res)
          setError(false)
          setMessage('Organisation Enabled successfully')
          setPostModal(true)
          
          onRefresh()
        }
  }
  const handleDisable = async(id) =>{
    const data ={
      id,
      payload:{
        action:'disable'
      }
    }
        const res = await dispatch(
          updateOrganisationStatus(data)
        );
        if (!res.error) {
          console.log(res)
          setError(false)
          setMessage('Organisation Disabled successfully')
          setPostModal(true)
          
          onRefresh()
        }
  }
  const handleReject = async(id) =>{
    const data ={
      id,
      payload:{
        action:'reject'
      }
    }
        const res = await dispatch(
          updateOrganisationStatus(data)
        );
        if (!res.error) {
          console.log(res)
          setError(false)
          setMessage('Organisation Rejected successfully')
          setPostModal(true)
          
          onRefresh()
        }
  }
  const handleApprove = async(id) =>{
    const data ={
      id,
      payload:{
        action:'approve'
      }
    }
        const res = await dispatch(
          updateOrganisationStatus(data)
        );
        if (!res.error) {
          console.log(res)
          setError(false)
          setMessage('Organisation Approved successfully')
          setPostModal(true)
          
          onRefresh()
        }
  }

  const data = fetchedOrgs?.map((orgs) => ({
    key: orgs._id,
    name: orgs.companyName,
    areas: orgs.areaOfAccess,
    phone: orgs.phone,
    address: orgs.location,
    tag: orgs.companyTag,
  }));

  const Tdata = [
    {
      title: "New Organisations",
      link: "New Organisations",
      data: awaintingOrgs,
      totalPages: awaitingPaginationData?.totalPages,
      paginationData: awaitingPaginationData,
      filterHandler: onFilter,
      searchHandler: onSearch,
      fetch: fetchAllAwaiting,
      onRefresh: onRefresh,
      columns: [
        {
          title: "Organization Name",
          dataIndex: "organisationName",
          key: "organisationName",
          // render: (text) => <a>{text}</a>,
        },
        // {
        //   title: "Area of Access",
        //   dataIndex: "streetOfAccess",
        //   key: "streetOfAccess",
        //   render: (areas) => (
        //     <span>
        //       {(areas?.slice(0, 3) || []).map((area) => {
        //         return <Tag key={area}>{area && area?.toUpperCase()}</Tag>;
        //       })}
        //     </span>
        //   ),
        // },
        {
          title: "Contact Line",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Organization Tag",
          dataIndex: "tagLine",
          key: "tagLine",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Vector
                  onClick={() => {
                    setSelectedInfo(record);
                  }}
                  className="relative"
                >
                  <TotalOrgActions
                    data={selectedInfo}
                    onRefresh={onRefresh}
                    type={'new'}
                    rejectHandler={() => handleReject(record?._id)}
                    approveHandler={() => handleApprove(record?._id)}
                  />

                </Vector>

              </Space>
            );
          },
        },
      ],
    },
    {
      title: "Approved Organisations",
      link: "Approved Organisations",
      data: fetchedOrgs,
      totalPages: paginationData?.totalPages,
      paginationData: paginationData,
      filterHandler: onFilter,
      searchHandler: onSearch,
      fetch: fetchAll,
      onRefresh: onRefresh,
      columns: [
        {
          title: "Organization Name",
          dataIndex: "organisationName",
          key: "organisationName",
          // render: (text) => <a>{text}</a>,
        },
        // {
        //   title: "Area of Access",
        //   dataIndex: "streetOfAccess",
        //   key: "streetOfAccess",
        //   render: (areas) => (
        //     <span>
        //       {(areas?.slice(0, 3) || []).map((area) => {
        //         return <Tag key={area}>{area && area?.toUpperCase()}</Tag>;
        //       })}
        //     </span>
        //   ),
        // },
        {
          title: "Contact Line",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Organization Tag",
          dataIndex: "tagLine",
          key: "tagLine",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Vector
                  onClick={() => {
                    setSelectedInfo(record);
                  }}
                  className="relative"
                >
                  <TotalOrgActions
                    data={selectedInfo}
                    onRefresh={onRefresh}
                    type={'approved'}
                    link={`/admin/profile_details/${record._id}`}
                    disableHandler={() => handleDisable(record?._id)}
                  />

                </Vector>

              </Space>
            );
          },
        },
      ],
    },
    {
      title: "Disabled Organisations",
      link: "Disabled Organisations",
      data: disabledOrgs,
      totalPages: disabledPaginationData?.totalPages,
      paginationData: disabledPaginationData,
      filterHandler: onFilter,
      searchHandler: onSearch,
      fetch: fetchAllDisabled,
      onRefresh: onRefresh,
      columns: [
        {
          title: "Organization Name",
          dataIndex: "organisationName",
          key: "organisationName",
          // render: (text) => <a>{text}</a>,
        },
        // {
        //   title: "Area of Access",
        //   dataIndex: "streetOfAccess",
        //   key: "streetOfAccess",
        //   render: (areas) => (
        //     <span>
        //       {(areas?.slice(0, 3) || []).map((area) => {
        //         return <Tag key={area}>{area && area?.toUpperCase()}</Tag>;
        //       })}
        //     </span>
        //   ),
        // },
        {
          title: "Contact Line",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Organization Tag",
          dataIndex: "tagLine",
          key: "tagLine",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Vector
                  onClick={() => {
                    setSelectedInfo(record);
                  }}
                  className="relative"
                >
                  <TotalOrgActions
                    data={selectedInfo}
                    onRefresh={onRefresh}
                    type={'disabled'}
                    link={`/admin/profile_details/${record._id}`}
                    enableHandler={() => handleEnable(record?._id)}
                  />

                </Vector>

              </Space>
            );
          },
        },
      ],
    },
    {
      title: "Rejected Organisations",
      link: "Rejected Organisations",
      data: rejectedOrgs,
      totalPages: rejectedPaginationData?.totalPages,
      paginationData: rejectedPaginationData,
      filterHandler: onFilter,
      searchHandler: onSearch,
      fetch: fetchAllRejected,
      onRefresh: onRefresh,
      columns: [
        {
          title: "Organization Name",
          dataIndex: "organisationName",
          key: "organisationName",
          // render: (text) => <a>{text}</a>,
        },
        // {
        //   title: "Area of Access",
        //   dataIndex: "streetOfAccess",
        //   key: "streetOfAccess",
        //   render: (areas) => (
        //     <span>
        //       {(areas?.slice(0, 3) || []).map((area) => {
        //         return <Tag key={area}>{area && area?.toUpperCase()}</Tag>;
        //       })}
        //     </span>
        //   ),
        // },
        {
          title: "Contact Line",
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: "Organization Tag",
          dataIndex: "tagLine",
          key: "tagLine",
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          render: (text, record) => {
            return (
              <Space size="middle">
                <Vector
                  onClick={() => {
                    setSelectedInfo(record);
                  }}
                  className="relative"
                >
                  <TotalOrgActions
                    data={selectedInfo}
                    onRefresh={onRefresh}
                    type={'rejected'}
                    approveHandler={() => handleApprove(record?._id)}
                  />

                </Vector>

              </Space>
            );
          },
        },
      ],
    },

  ];

  return (
    <OrganizationContainer>
      {/* <OrganizationHeader>
        {orgPermissions?.create && (
        <Link to="/admin/total_organizations_setup">
          <StyledButton
            buttonStyle="btn--primary--outline"
            buttonSize="btn--medium"
            className="flex justify-between items-center"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            Add Organizations
          </StyledButton>
        </Link>
        )}
      </OrganizationHeader> */}

      {/* <DataTable
        data={data}
        onSearch={onSearch}
        columns={columns}
        setCurrentPage={setCurrentPage}
        totalPages={paginationData?.totalPages}
        onFilter={onFilter}
        onRefresh={onRefresh}
        onFetch={fetchAll}
        paginationData={paginationData}
        header
      /> */}

      <Modal
        color={error ? "red" : "#005700"}
        type="postAction"
        show={showPostModal}
        close={() => setPostModal(false)}
      >
        {!error ? message : error}
      </Modal>

        <Tabcontent
          data={Tdata}
          // onSwitch={onSwitch}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          onRefresh={onRefresh}
        />
    </OrganizationContainer>
  );
};

export default TotalOrganization;



