import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import Option from "../../components/UI/Option";
import BreadCrumb from "../../components/UI/breadCrumbs";
import { FlexContainer } from "../../components/styledElements/index";
import {
  disableOrganisation,
  enableOrganisation,
  findOrganisation,
} from "../../store/actions";
import DataTable from "../../components/UI/Table";
import { truncate } from "../../utils/constants";

const OrganizationContainer = styled.div`
  //   display: grid;
  //   grid-template-coloumns: auto 1fr;
  margin-bottom: 20px;
  display: grid;
`;

const NavBarLeft = styled.div`
  ${tw`flex justify-between`}

  .text {
    font-size: 15px;
    color: "#0e0e0e";
  }
`;
const ModalBackground = styled.div`
  ${tw`p-3 mt-4`}
  background-color: white!important;
`;

const OrgainzationTitle = styled.div`
  font-size: 20px;
  margin-left: 20px;
  margin-top: 30px;
`;

const ColumnStyle = styled.div`
  letter-spacing: 1px;
  margin-top: 30px;
  margin-left: 30px;
  justify-content: space-between;
  color: green;
  font-size: 17px;
`;

const InfoWrapper = styled.div`
  ${tw`flex flex-wrap gap-10 gap-x-12 w-11/12`}
`;
const InfoItem = styled.div`
  ${tw`flex flex-col space-y-2`}
`;

const InfoTitle = styled.p`
  ${tw`font-semibold text-sm leading-[22px] text-secondary`}
`;
const InfoValue = styled.p`
  ${tw`font-bold text-base leading-[28px]`};
  color: ${(props) => (props.color ? props.color : "#464F54")};
`;

const ProfileDetails = ({ match }) => {
  const user_claims = JSON.parse(localStorage.getItem('Claims'))
  const {
    organisation: { selectedOrganisation },
    auth: {
      userInfo: { claims },
    },
  } = useSelector((state) => state);

  // const orgPermissions = claims?.claims?.find(
  //   (claim) => claim.claimId.title === claimPermissions.ORGANISATION.title
  // );

  // const { error } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(findOrganisation(match?.params?.id));
  }, []);

  const selectedOrg =selectedOrganisation?.organisationprofile
  
  const data = [
    {
      title: "Organization Name",
      value: selectedOrg?.organisationName,
    },
    {
      title: "Contact Address",
      value: selectedOrg?.address,
    },
    {
      title: "Contact Line",
      value: selectedOrg?.phone,
    },
    {
      title: "Organization Tag",
      value: selectedOrg?.tagLine,
    },
    {
      title: "Email",
      value: selectedOrg?.email,
    },
  ];
  const pickup_pricing_data = [

    // {
    //   title: "Areas of Access",
    //   value: selectedOrganisation?.areaOfAccess.map((access) => access),
    // },
    // {
    //   title: "Coverage Area Under Selected LCDA",
    //   value: selectedOrganisation?.streetOfAccess.map((access) => access),
    // },
    {
      title: "Household Pick-up Prices",
      value: selectedOrganisation?.organisationPricing
        ? selectedOrganisation?.organisationPricing[0]?.categories.map(
            (cat) => `${cat.name}: #${cat.price}`
          )
        : "No prices available..",
    },
    {
      title: "Aggregator Pick-up Prices",
      value: selectedOrganisation?.organisationPricing
        ? selectedOrganisation?.organisationPricing[1]?.categories.map(
            (cat) => `${cat.name}: #${cat.price}`
          )
        : "No prices available..",
    },
    {
      title: "Industrial Pick-up Prices",
      value: selectedOrganisation?.organisationPricing
        ? selectedOrganisation?.organisationPricing[2]?.categories.map(
            (cat) => `${cat.name}: #${cat.price}`
          )
        : "No prices available..",
    },

  ];
  const dropoff_pricing_data = [

    // {
    //   title: "Areas of Access",
    //   value: selectedOrganisation?.areaOfAccess.map((access) => access),
    // },
    // {
    //   title: "Coverage Area Under Selected LCDA",
    //   value: selectedOrganisation?.streetOfAccess.map((access) => access),
    // },
    {
      title: "Household Drop-Off Prices",
      value: selectedOrganisation?.organisationPricing
        ? selectedOrganisation?.organisationPricing[0]?.categories.map(
            (cat) => `${cat.name}: #${cat.price}`
          )
        : "No prices available..",
    },
    {
      title: "Aggregator Drop-Off Prices",
      value: selectedOrganisation?.organisationPricing
        ? selectedOrganisation?.organisationPricing[1]?.categories.map(
            (cat) => `${cat.name}: #${cat.price}`
          )
        : "No prices available..",
    },
    {
      title: "Industrial Drop-Off Prices",
      value: selectedOrganisation?.organisationPricing
        ? selectedOrganisation?.organisationPricing[2]?.categories.map(
            (cat) => `${cat.name}: #${cat.price}`
          )
        : "No prices available..",
    },

  ];

  const pages = [{ name: "Organization", link: "/admin/total_organizations" }];

  const [message, setMessage] = useState("");
  const [deleteflag, setDeleteModaFlagl] = useState(true);

  const handleDisableOrganisation = async () => {
    try {
      const res = await dispatch(
        disableOrganisation(selectedOrganisation?._id)
      );
      if (!res.error) {
        setMessage(res.payload.message);
        // setPostAction(true);
      }
    } catch (error) {}
  };

  const handleEnableOrganisation = async () => {
    try {
      const res = await dispatch(enableOrganisation(selectedOrganisation?._id));
      if (!res.error) {
        setMessage(res.payload.message);
        // setPostAction(true);
      }
    } catch (error) {}
  };

  const optiondata = [
    {
      pathname: "/admin/total_aggregators_all/",
      title: "See Aggregators",
    },
    {
      pathname: "/admin/total_organizations_wastePicker/",
      title: "Waste Pickers",
      state: selectedOrganisation,
    },
    {
      pathname: "/admin/total_organizations_generated_invoices/",
      title: "Generated Invoices",
    },
    {
      pathname: "/admin/total_organizations_completed_schedules/",
      title: "Completed Schedules",
    },

  ...((user_claims?.edit) ? [
    {
      pathname: "/admin/total_organizations_modify/",
      title: "Modify Organization",
    },
    {
      title: "Disable Organization",
      handler: handleDisableOrganisation,
    },
    {
      title: "Enable Organization",
      handler: handleEnableOrganisation,
    }
  ] : []),
  ...((user_claims?.delete) ? [
    {
      title: "Delete Organization",
      deleteflag: deleteflag,
    }
  ] : [])
  ];


  const columns = [
    {
      title: "Hub Name",
      dataIndex: "companyName",
      key: "companyName",
      render: (text) => <p>{truncate(text, 30)}</p>,
    },

    // {
    //   title: "Created Date",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
    // },

    // {
    //   title: "Pickup Location",
    //   dataIndex: "address",
    //   key: "address",
    //   render: (text) => <p>{truncate(text, 30)}</p>,
    // },

    // {
    //   title: "Waste Category",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (wastes) => (
    //     <span>
    //       {(wastes?.slice(0, 3) || []).map((waste) => {
    //         return <Tag key={waste}>{waste?.name || waste}</Tag>;
    //       })}
    //     </span>
    //   ),
    // },

    // {
    //   title: "Waste Category",
    //   dataIndex: "categories",
    //   key: "categories",
    //   render: (categories) => (
    //     <span>
    //       {(categories.slice(0, 3) || []).map((waste) => {
    //         return (
    //           <Tag key={waste}>
    //             <Popover content={waste?.name || waste}>
    //               {truncate(waste?.name, 10)}
    //             </Popover>
    //           </Tag>
    //         );
    //       })}
    //     </span>
    //   ),
    // },

    // {
    //   title: "Customer Phone",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Waste Quantity",
    //   dataIndex: "quantity",
    //   key: "quantity",
    // },

    // {
    //   title: "Pickup Date",
    //   dataIndex: "pickUpDate",
    //   key: "pickUpDate",
    //   render: (text) => <p>{moment(text).format("YYYY-MM-DD")}</p>,
    // },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {/* <Button
    //         type=""
    //         onClick={() => {
    //           setRowInfo(record);
    //           setShowPending(true);
    //         }}
    //       >
    //         See More
    //       </Button> */}

    //       <Button
    //         type=""
    //         onClick={() => {
    //           setRowInfo(record);
    //           setShowModal(true);
    //         }}
    //       >
    //         See More
    //       </Button>

    //       {/* <a>See More</a> */}
    //     </Space>
    //   ),
    // },
  ];

  

  return (
    <>
      <OrganizationContainer>
        <NavBarLeft>
          <BreadCrumb pages={pages} current="Profile Details" />

          {/* {orgPermissions?.create && (
            <Link to="/admin/total_organizations_setup">
              <StyledButton
                buttonStyle="btn--primary--outline"
                buttonSize="btn--medium"
                className="flex justify-between items-center"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                Add Organization
              </StyledButton>
            </Link>
          )} */}
        </NavBarLeft>
      </OrganizationContainer>

      <ModalBackground>
        <OrgainzationTitle>
          Organization Details
          <Option
            selectedOrganisation={selectedOrganisation}
            optiondata={optiondata}
            message={message}
          />
        </OrgainzationTitle>
        <ColumnStyle>
          <InfoWrapper>
            {data.map(({ title, value }) => {
              let color = "";
              if (["status", "missed pickup"].includes(title.toLowerCase()))
                color = "red";
              else if (
                title.toLowerCase() === "status" &&
                value.toLowerCase() !== "pending"
              )
                color = "#005700";

              return (
                <InfoItem>
                  <InfoTitle>{title}:</InfoTitle>
                  {typeof value !== "object" ? (
                    title.toLowerCase() !== "status" ? (
                      <InfoValue color={color}>{value}</InfoValue>
                    ) : (
                      <FlexContainer>
                        <InfoValue color={color}>{value}</InfoValue>:
                      </FlexContainer>
                    )
                  ) : (
                    <FlexContainer>
                      {value.map((v) => (
                        <Tag>{v}</Tag>
                      ))}
                    </FlexContainer>
                  )}
                </InfoItem>
              );
            })}
          </InfoWrapper>
        </ColumnStyle>
      </ModalBackground>

      <ModalBackground>
        <OrgainzationTitle>
          Organization Hubs
        </OrgainzationTitle>
            <div className="px-[2rem]">
              {
                selectedOrganisation?.hubs.map((i, id)=> <p><span>{id+1}.</span> {i.companyName}</p> )
              }
            </div>


            {/* <DataTable
              data={selectedOrganisation?.hubs}
              columns={columns}
              header
              onSearch={onSearch}
              setCurrentPage={setCurrentPage}
              onFilter={onFilter}
              onRefresh={onRefresh}
              totalPages={totalPages}
              onFetch={fetchPending}
              paginationData={paginationData}
            />  */}
      </ModalBackground>
      <ModalBackground>
        <OrgainzationTitle>
          Organization Pickup Pricing
        </OrgainzationTitle>
        <ColumnStyle>
          <InfoWrapper>
            {pickup_pricing_data?.map(({ title, value }) => {
              let color = "";
              if (["status", "missed pickup"].includes(title.toLowerCase()))
                color = "red";
              else if (
                title.toLowerCase() === "status" &&
                value.toLowerCase() !== "pending"
              )
                color = "#005700";

              return (
                <InfoItem>
                  <InfoTitle>{title}:</InfoTitle>
                  {typeof value !== "object" ? (
                    title.toLowerCase() !== "status" ? (
                      <InfoValue color={color}>{value}</InfoValue>
                    ) : (
                      <FlexContainer>
                        <InfoValue color={color}>{value}</InfoValue>:
                      </FlexContainer>
                    )
                  ) : (
                    <FlexContainer>
                      {value.map((v) => (
                        <Tag>{v}</Tag>
                      ))}
                    </FlexContainer>
                  )}
                </InfoItem>
              );
            })}
          </InfoWrapper>
        </ColumnStyle>
      </ModalBackground>

      <ModalBackground>
        <OrgainzationTitle>
          Organization Drop-off Pricing
        </OrgainzationTitle>
        <ColumnStyle>
          <InfoWrapper>
            {dropoff_pricing_data?.map(({ title, value }) => {
              let color = "";
              if (["status", "missed pickup"].includes(title.toLowerCase()))
                color = "red";
              else if (
                title.toLowerCase() === "status" &&
                value.toLowerCase() !== "pending"
              )
                color = "#005700";

              return (
                <InfoItem>
                  <InfoTitle>{title}:</InfoTitle>
                  {typeof value !== "object" ? (
                    title.toLowerCase() !== "status" ? (
                      <InfoValue color={color}>{value}</InfoValue>
                    ) : (
                      <FlexContainer>
                        <InfoValue color={color}>{value}</InfoValue>:
                      </FlexContainer>
                    )
                  ) : (
                    <FlexContainer>
                      {value.map((v) => (
                        <Tag>{v}</Tag>
                      ))}
                    </FlexContainer>
                  )}
                </InfoItem>
              );
            })}
          </InfoWrapper>
        </ColumnStyle>
      </ModalBackground>
    </>
  );
};

export default ProfileDetails;
