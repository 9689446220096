import { PageContainer } from "../components/styledElements";
import baseAxios from "../core/api/axios/baseAxios";

export default class ScheduleService {
  /****************************
   *
   * getter services
   *
   ****************************/
  static async getPendingSchedule(page, date) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=pendingSchedules&page=${page}`
      // `/v2/schedules?start=${date.start}&end=${date.end}&completionStatus=pending&page=${page}`
    );
    return res?.data || res;
  }
  static async getCompanyPendingSchedules() {
    const res = await baseAxios.get(`/v2/collectors/schedules/pending`);

    return res?.data || res;
  }

  static async getAcceptedSchedule(page, data) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=acceptedSchedules&page=${page}`
      // `/v2/schedules?start=${data.start}&end=${data.end}&completionStatus=accepted&page=${page}`
    );
    return res?.data || res;
  }

  static async getCompanyAcceptedSchedule(page, data) {
    const res = await baseAxios.get(
      `/v2/company-schedules?start=${data.start}&end=${data.end}&completionStatus=accepted&page=${page}`
    );
    return res?.data || res;
  }

  static async getCompletedSchedule(page, data) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=totalCompleted&page=${page}`
      // `/v2/admin/schedules/completed?start=${data.start}&end=${data.end}&completionStatus=completed&page=${page}`
    );
    return res?.data || res;
  }

  static async getCompanyCompletedSchedule(page, data, scheduleApproval) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?start=${data.start}&end=${data.end}&completionStatus=completed&page=${page}&scheduleApproval=${scheduleApproval}`
    );
    return res?.data || res;
  }

  static async getCancelledSchedule(page, data) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=cancelledSchedules&page=${page}`
      // `/v2/schedules?start=${data.start}&end=${data.end}&completionStatus=cancelled&page=${page}`
    );
    return res?.data || res;
  }

  static async getCompanyCancelledSchedule(page, data) {
    const res = await baseAxios.get(
      `/v2/company-schedules?start=${data.start}&end=${data.end}&completionStatus=cancelled&page=${page}`
    );
    return res?.data || res;
  }

  static async getMissedSchedule(page, data) {
    const res = await baseAxios.get(
      // `/v2/missed-schedules?start=${data.start}&end=${data.end}&completionStatus=missed&page=${page}`
      // `/v2/schedules?start=${data.start}&end=${data.end}&completionStatus=missed&page=${page}`
      `/v2/dashboard/details?type=missedSchedulePickup&page=${page}`
    );
    return res?.data || res;
  }

  static async getCompanyMissedSchedule(page, data) {
    const res = await baseAxios.get(
      `/v2/company-schedules?start=${data.start}&end=${data.end}&completionStatus=missed&page=${page}`
    );
    return res?.data || res;
  }
  /****************************
   *
   * search services
   *
   ****************************/
  static async searchPendingSchedule(searchQuery, page) {
    const res = await baseAxios.get(
       `/v2/dashboard/details?type=pendingSchedules&page=${page}&searchTerm=${searchQuery}`
      // `/v2/schedules?key=${searchQuery}&completionStatus=pending&page=${page}`
    );

    return res?.data || res;
  }

  static async searchAcceptedSchedule(key, page) {
    const res = await baseAxios.get(
     `/v2/dashboard/details?type=acceptedSchedules&page=${page}&searchTerm=${key}`
    );

    return res?.data || res;
  }

  static async searchCompanyAcceptedSchedule(page, key) {
    const res = await baseAxios.get(
      `/v2/company-schedules?key=${key}&completionStatus=accepted&page=${page}`
    );

    return res?.data || res;
  }
  // static async searchCompletedSchedule(searchQuery, page) {
  //   const res = await baseAxios.get(
  //     `/v2/schedules?key=${searchQuery}&completionStatus=completed&page=${page}`
  //   );

  //   return res?.data || res;
  // }

  static async searchCompletedSchedule(key, page) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=totalCompleted&page=${page}&searchTerm=${key}`
    );

    return res?.data || res;
  }

  static async searchCompanyCompletedSchedule(page, key) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?key=${key}&page=${page}&completionStatus=completed&scheduleApproval=pending`
    );

    return res?.data || res;
  }

  // static async searchCancelledSchedule(searchQuery, page) {
  //   const res = await baseAxios.get(
  //     `/v2/schedules?key=${searchQuery}&completionStatus=cancelled&page=${page}`
  //   );
  //   return res?.data || res;
  // }

  static async searchCancelledSchedule(key, page) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=cancelledSchedules&page=${page}&searchTerm=${key}`
    );
    return res?.data || res;
  }

  static async searchCompanyCancelledSchedule(page, key) {
    const res = await baseAxios.get(
      `/v2/company-schedules?key=${key}&completionStatus=cancelled&page=${page}`
    );
    return res?.data || res;
  }

  static async searchMissedSchedule(key, page) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=missedSchedules&page=${page}&searchTerm=${key}`
    );
    return res?.data || res;
  }

  static async searchCompanyMissedSchedule(page, key) {
    const res = await baseAxios.get(
      `/v2/company-schedules?key=${key}&completionStatus=missed&page=${page}`
    );
    return res?.data || res;
  }

  /****************************
   *
   * filter services
   *
   ****************************/
  static async filterPendingSchedule(page, currentMonth) {

    console.log(currentMonth)
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=pendingSchedules&filterType=custom&startDate=${currentMonth.start}&endDate=${currentMonth.end}`
      // `/v2/schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=pending&page=${page}`
    );
    return res?.data || res;
  }

  static async filterCompanyPendingSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/company-schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=pending&page=${page}`
    );
    return res?.data || res;
  }
  static async filterAcceptedSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=acceptedSchedules&filterType=custom&startDate=${currentMonth.start}&endDate=${currentMonth.end}&page=${page}`
      // `/v2/schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=accepted&page=${page}`
    );
    return res?.data || res;
  }

  static async filterCompanyAcceptedSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/company-schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=accepted&page=${page}`
    );
    return res?.data || res;
  }

  static async filterCompletedSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=totalCompleted&filterType=custom&startDate=${currentMonth.start}&endDate=${currentMonth.end}&page=${page}`
      // `/v2/admin/schedules/completed?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=completed&page=${page}`
    );
    return res?.data || res;
  }

  static async filterCompanyCompletedSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?start=${currentMonth.start}&end=${currentMonth.end}&page=${page}&completionStatus=completed&scheduleApproval=pending`
    );
    return res?.data || res;
  }

  static async filterCancelledSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      // `/v2/schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=cancelled&page=${page}`
      `/v2/dashboard/details?type=cancelledSchedules&filterType=custom&startDate=${currentMonth.start}&endDate=${currentMonth.end}&page=${page}`
    );
    return res?.data || res;
  }

  static async filterCompanyCancelledSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/company-schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=cancelled&page=${page}`
    );
    return res?.data || res;
  }

  static async filterMissedSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/dashboard/details?type=missedSchedulePickup&filterType=custom&startDate=${currentMonth.start}&endDate=${currentMonth.end}&page=${page}`
      // `/v2/schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=missed&page=${page}`
    );
    return res?.data || res;
  }

  static async filterCompanyMissedSchedule(page, currentMonth) {
    const res = await baseAxios.get(
      `/v2/company-schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=missed&page=${page}`
    );
    return res?.data || res;
  }

  static async approveCompletedSchedules(data) {
    const res = await baseAxios.post(`/v2/schedule/approve`, data);
    return res?.data || res;
  }

  static async rejectCompletedSchedules(data) {
    const res = await baseAxios.post(`/v2/schedule/disapprove`, data);
    return res?.data || res;
  }
  static async getCompanyApproveCompletedSchedule(
    page,
    data,
    scheduleApproval
  ) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?start=${data.start}&end=${data.end}&page=${page}&scheduleApproval=${scheduleApproval}`
    );
    return res?.data || res;
  }

  static async searchCompanyApproveCompletedSchedule(
    key,
    page,
    scheduleApproval
  ) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?key=${key}&page=${page}&completionStatus=completed&scheduleApproval=${scheduleApproval}`
    );
    return res?.data || res;
  }

  static async filterCompanyApproveCompletedSchedule(
    page,
    data,
    scheduleApproval
  ) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?start=${data.start}&end=${data.end}&page=${page}&completionStatus=completed&scheduleApproval=${scheduleApproval}`
      // `/v2/company-schedules/completed?start=${data.start}&end=${data.end}&page=${page}&completionStatus=completed&scheduleApproval=${scheduleApproval}`
    );
    return res?.data || res;
  }

  static async filterCompanyRejectCompletedSchedule(
    page,
    data,
    scheduleApproval
  ) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?start=${data.start}&end=${data.end}&page=${page}&completionStatus=completed&scheduleApproval=${scheduleApproval}`
    );
    return res?.data || res;
  }

  static async searchCompanyRejectCompletedSchedule(
    key,
    page,
    scheduleApproval
  ) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?key=${key}&page=${page}&completionStatus=completed&scheduleApproval=${scheduleApproval}`
    );
    return res?.data || res;
  }

  static async getCompanyRejectCompletedSchedule(page, data, scheduleApproval) {
    const res = await baseAxios.get(
      `/v2/company-schedules/completed?start=${data.start}&end=${data.end}&page=${page}&completionStatus=completed&scheduleApproval=${scheduleApproval}`
    );
    return res?.data || res;
  }

  static async filterCompanyApprovedCompletedSchedule(
    page,
    currentMonth,
    scheduleApproval
  ) {
    const res = await baseAxios.get(
      `/v2/company-schedules?start=${currentMonth.start}&end=${currentMonth.end}&completionStatus=completed&page=${page}&scheduleApproval=${scheduleApproval}`
    );
    return res?.data || res;
  }
}
